import { SupportedLanguage } from "../../../interfaces";
import { getApiRequestService } from "../../../services/index.services";
import { useNectarCacheScroller } from "../hooks/useNectarCache";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguage;
  medicalSpecialties?: string;
  tags?: string;
}

export async function getNectarsList(params: QueryParams) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/nectar`, {
      params: {
        ...params,
        status: "published",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export function useNectarsListWithCache() {
  const cache = useNectarCacheScroller();

  // TODO: Missing catch.
  return async function (params: QueryParams) {
    const isAdding = params.offset === -1;
    if (isAdding) params.offset = cache.list.length;
    const { docs, meta } = await getNectarsList(params);
    cache.update(isAdding ? [...cache.list, ...docs] : docs, meta.total);
    
    return { docs, meta };
  }
}
