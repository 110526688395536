import { getApiRequestService } from "../../../services/index.services";

export async function viewPlaylist(playlistId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(`/playlist/view/${playlistId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
