import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useReadyState } from "../../domains/app/hooks/useReadyState";
import { useCountdown } from "../../domains/app/hooks/useCountdown";
import { Swiper, SwiperSlide } from "swiper/react";
import StoryCoverArticle from "./StoryCoverArticle";
import StoryCoverNectar from "./StoryCoverNectar";
import StoryVideo from "../videos/StoryVideo";
import { StoryTimerWrapper } from "./layout";
import CircleProgress from "../CircleProgress";
import ForYouBlenderEmpty from "./ForYouBlenderEmpty";
import ForYouBlender from "./ForYouBlender";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventForYouExecuteBlender } from "../../tools/analytics/forYouAnalytics";
import { HapticEffect } from "../../interfaces";
import { iosGenerateHaptic, iosStopHaptic } from "../../tools/ios";
import styled from "styled-components";

export default function ForYouMainSlider({
  contentList,
  isLoading,
  isLocked,
  onSlideChange,
}: {
  contentList: any[];
  isLoading: boolean;
  isLocked: boolean;
  onSlideChange: (index: number) => void;
}) {
  const history = useHistory();
  const location = useLocation();
  const { activeLang } = useLangContext();
  const [slideIndex, setSlideIndex] = useState(
    localStorage.getItem("foryou_carousel_index")
      ? parseInt(localStorage.getItem("foryou_carousel_index") ?? "0")
      : 0
  );
  const { setAsReady, useReadyEffect } = useReadyState();
  const countdown = useCountdown(8, slideNext);
  const swiperRef = useRef<any | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const containerHeight = scrollContainerRef.current?.clientHeight ?? 0;

  useEffect(() => {
    if (!contentList.length) {
      startBlenderAnimation();
    }

    return () => {
      iosStopHaptic();
    };
  }, []);

  useEffect(() => {
    countdown.stop();
    countdown.reset();
    swiperRef.current?.slideTo(0);
    setAsReady();
  }, [activeLang]);
  
  useReadyEffect(() => {
    setSlideIndex(0);
  }, [activeLang]);

  useEffect(() => {
    if (!isLoading) iosStopHaptic();
  }, [isLoading]);

  useEffect(() => {
    // Start timer to move to the next content
    if (!isLocked && !!contentList.length) {
      countdown.reset();
      countdown.stop();

      const displayedContent = contentList[slideIndex];
      if (!("apiVideo" in displayedContent)) {
        if (slideIndex < contentList.length - 1) {
          countdown.start();
        }
      }
    }
  }, [slideIndex, isLocked]);


  function startBlenderAnimation() {
    gaEventForYouExecuteBlender();
    iosGenerateHaptic(HapticEffect.BLENDER);
  }

  function memorizeIndexToStorage() {
    localStorage.setItem("foryou_carousel_index", slideIndex.toString());
  }

  function slideNext() {
    swiperRef.current?.slideNext();
  }

  return (
    <Container ref={scrollContainerRef}>
      {isLoading ? (
        <ForYouBlender />)
        : !contentList.length ? (
          <ForYouBlenderEmpty />
        ) : (containerHeight > 0) && (
          <Swiper
            onSwiper={(swiper) => swiperRef.current = swiper}
            direction="vertical"
            className="swiper"
            style={{
              height: containerHeight,
            }}
            initialSlide={slideIndex}
            onRealIndexChange={(swiper: { activeIndex: number }) => {
              setSlideIndex(swiper.activeIndex);
              onSlideChange(swiper.activeIndex);
            }}
          >
            {contentList?.map((content: any, index: number) =>
              "apiVideo" in content ? (
                <SwiperSlide key={content?._id}>
                  <StoryVideo
                    height={containerHeight}
                    video={content}
                    isVisible={slideIndex === index}
                    analyticsListName={AnalyticsItemList.FORYOU}
                    onEnd={() => {
                      slideNext();
                    }}
                    onClickLink={memorizeIndexToStorage}
                  />
                </SwiperSlide>
              ) : "answer" in content ? (
                <SwiperSlide key={content?._id}>
                  <StoryCoverNectar
                    nectar={content}
                    onClick={() => {
                      memorizeIndexToStorage();
                      history.push({
                        pathname: `${location.pathname}/story/nectar/${content.slug}`,
                        state: { content },
                      });
                    }}
                    onOptionsOpen={() => countdown.reset()}
                    onOptionsClose={() => countdown.start()}
                  />
                </SwiperSlide>
              ) : (
                <SwiperSlide key={content?._id}>
                  <StoryCoverArticle
                    article={content}
                    onClick={() => {
                      memorizeIndexToStorage();
                      history.push({
                        pathname: `${location.pathname}/story/article/${content.slug}`,
                        state: { content },
                      });
                    }}
                    onOptionsOpen={() => countdown.reset()}
                    onOptionsClose={() => countdown.start()}
                  />
                </SwiperSlide>
              )
            )}
          </Swiper>
        )}

      <StoryTimerWrapper
        className={(countdown.isRunning && countdown.timeLeftSec <= 5) ? "visible" : ""}
      >
        <CircleProgress value={countdown.timeLeftSec * 20}>
          <span>{countdown.isRunning && countdown.timeLeftSec <= 5 ? countdown.timeLeftSec.toFixed(0) : ""}</span>
        </CircleProgress>
      </StoryTimerWrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  background: linear-gradient(180deg, #FFC408 0%, rgba(255, 255, 255, 0.00) 100%), #F7F6F2;

  .swiper .swiper-slide {
    display: flex;
  }
`;
