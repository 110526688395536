import styled from "styled-components";
import { useLangContext } from "../../domains/app/contexts/lang.context";

export default function TakedaBanner() {
  const { t } = useLangContext();

  return (
    <Container>{t("room:discovery.takedaBanner")}</Container>
  );
}

const Container = styled.div`
  padding: 12px 0;
  background-color: #ed1c24;
  color: #fff;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: center;
`;
