import { atom, useAtom } from "jotai";
import { NectarModel } from "../nectar.types";
import { DebugLogger } from "../../app/utils/debugLogger";
import { useRoomContext } from "../../app/contexts/room.context";

const debug = new DebugLogger("Nectar Cache");
debug.mute();

enum Cache {
  PUBLIC_SCROLLER = "public_scroller",
  PUBLIC_LIST = "public_list",
  ROOM_SCROLLER = "room_scroller",
  ROOM_LIST = "room_list",
  SWIPE_LIST = "swipe_list",
}

interface CacheListData {
  list: NectarModel[];
  total: number;
}

interface CacheLists {
  [Cache.PUBLIC_SCROLLER]: CacheListData;
  [Cache.PUBLIC_LIST]: CacheListData;
  [Cache.ROOM_SCROLLER]: CacheListData;
  [Cache.ROOM_LIST]: CacheListData;
  [Cache.SWIPE_LIST]: CacheListData;
}

const defaultCacheLists: CacheLists = {
  [Cache.PUBLIC_SCROLLER]: { list: [], total: 0 },
  [Cache.PUBLIC_LIST]: { list: [], total: 0 },
  [Cache.ROOM_SCROLLER]: { list: [], total: 0 },
  [Cache.ROOM_LIST]: { list: [], total: 0 },
  [Cache.SWIPE_LIST]: { list: [], total: 0 },
};

const cacheListsAtom = atom<CacheLists>(defaultCacheLists);
const swipeIndexAtom = atom<number>(-1);

function useNectarCache(cacheId: Cache) {
  const [cacheLists, setCacheLists] = useAtom(cacheListsAtom);

  function saveCache(listType: Cache, docs: NectarModel[], total: number) {
    debug.log("Save cache", { listType, docs, total });
    setCacheLists((lists) => ({ ...lists, [listType]: { list: docs, total } }));
  }

  return {
    list: cacheLists[cacheId].list,
    total: cacheLists[cacheId].total,
    update: (nectars: NectarModel[], total: number) => {
      saveCache(cacheId, nectars, total);
    },
  };
}

// Scroller nectars (public or room)
export function useNectarCacheScroller() {
  const { isInRoom } = useRoomContext();
  const publicCache = useNectarCache(Cache.PUBLIC_SCROLLER);
  const roomCache = useNectarCache(Cache.ROOM_SCROLLER);
  return !isInRoom ? publicCache : roomCache;
}

// List page nectars (public or room)
export function useNectarCacheListPage() {
  const { isInRoom } = useRoomContext();
  const publicCache = useNectarCache(Cache.PUBLIC_LIST);
  const roomCache = useNectarCache(Cache.ROOM_LIST);
  return !isInRoom ? publicCache : roomCache;
}

// Swipe list nectars
export function useNectarCacheSwipeList() {
  const cache = useNectarCache(Cache.SWIPE_LIST);
  const [swipeIndex, setSwipeIndex] = useAtom(swipeIndexAtom);
  return {
    ...cache,
    swipeIndex,
    setSwipeIndex,
  };
}
