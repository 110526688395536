import { Component } from "react";
import styled from "styled-components";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavBack } from "../../components/app/headers/layout";
import ItemList from "../../components/app/ItemList";
import Loader from "../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import PlaylistCard from "../../components/playlists/PlaylistCard";
import disableScroll from "disable-scroll";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { t } from "i18next";

const FETCH_LIMIT = 10;

class ProfilePlaylists extends Component {
  state = {
    playlists: null,
    meta: null,
    deleteList: [],
  };

  async componentDidMount() {
    disableScroll.off();
    window.scrollTo(0, 0);

    const { isRedirected } = await preflightUser({
      history: this.props.history,
      onboardingMessage: t("error:notOnboarded.default"),
    });
    if (isRedirected) return;

    this.fetchPlaylists();
  }

  fetchPlaylists = async () => {
    const { data } = await this.props.getPlaylists({
      limit: FETCH_LIMIT,
      offset: this.state.offset,
    });

    const { docs: playlists, meta } = data;

    this.setState({
      playlists: this.state.playlists
        ? [...this.state.playlists, ...playlists]
        : playlists,
      meta,
    });
  };

  render() {
    return (
      <Container>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderLogo />
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        {this.state.playlists && this.state.meta ? (
          <InfiniteScroll
            dataLength={this.state.playlists.length}
            next={() => {
              this.setState(
                {
                  offset: this.state.meta.offset + FETCH_LIMIT,
                },
                () => this.fetchPlaylists()
              );
            }}
            hasMore={this.state.playlists.length < this.state.meta.total}
          >
            <List>
              {this.state.playlists.map((playlist) => (
                <PlaylistCard
                  key={playlist._id}
                  playlist={playlist}
                  isFromProfile
                />
              ))}
            </List>
          </InfiniteScroll>
        ) : (
          <Loader loaderOnly />
        )}
      </Container>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}

const mapDispatch = {
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(withRouter(ProfilePlaylists));

const Container = styled.div`
  min-height: 100dvh;
  background: #f7f8fc;
`;

const List = styled(ItemList)`
  padding-top: 16px;
`;
