import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import disableScroll from "disable-scroll";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavBack,
} from "../../components/app/headers/layout";
import styled from "styled-components";
import Loader from "../../components/Loader";
import { getContentFromSlug } from "../../domains/content/endpoints/getContentFromSlug";
import { InfographicModel } from "../../interfaces/infographic.types";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ArticleCard from "../../components/cards/ArticleCard";
import NectarCard from "../../components/cards/NectarCard";
import VideoCard from "../../components/cards/VideoCard";
import ItemList from "../../components/app/ItemList";
import { ArticleModel } from "../../domains/article/article.types";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { ContentFormat } from "../../interfaces/content.interface";

export default function InfographicPage() {
  const location = useLocation<{ infographic: any }>();
  const params = useParams<{ slug: string }>();
  const { t } = useLangContext();
  const [infographic, setInfographic] = useState<InfographicModel | null>(null);
  const [infographicLoaded, setInfographicLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const infographicContainer = useRef<HTMLDivElement | null>(null);
  const [associatedArticles, setAssociatedArticles] = useState<ArticleModel[]>(
    []
  );
  const [associatedContent, setAssociatedContent] = useState<any[]>([]);

  useEffect(() => {
    disableScroll.off();
    fetchInfographic();
  }, []);

  async function fetchInfographic() {
    try {
      if (location.state?.infographic) {
        setInfographic(location.state?.infographic);
      }
      // Reload to fetch associated content.
      const data = await getContentFromSlug(params.slug);
      setInfographic(data);
      setAssociatedArticles(data.associatedArticles || []);
      setAssociatedContent(data.associatedContent || []);
    } catch (err) {
      console.error("Error fetching infographic:", err);
      setError("Failed to load infographic");
    }
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!infographic) {
    return <Loader />;
  }

  return (
    <>
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <HeaderTitle>{t("Infographic")}</HeaderTitle>
        <HeaderLinks />
      </HeaderBoxAutoSafe>

      <Wrapper>
        <InfographicContainer ref={infographicContainer}>
          {!infographicLoaded && <Loader background="#858585" />}
          <TransformWrapper disablePadding>
            <TransformComponent
              wrapperStyle={{
                height: infographicContainer.current?.clientHeight ?? "100dvh",
                overflowY: "auto",
              }}
            >
              <img
                src={infographic.image.url}
                alt={infographic.title || "infographic"}
                loading="lazy"
                onLoad={() => setInfographicLoaded(true)}
              />
            </TransformComponent>
          </TransformWrapper>
        </InfographicContainer>
      </Wrapper>

      {(associatedArticles.length > 0 || associatedContent.length > 0) && (
        <RelatedContentWrapper>
          <div className="handle" />
          <h2>{t("common:relatedContent")}</h2>
          <ItemList>
            {[...associatedArticles, ...associatedContent].map((content) => {
              const isArticle = "article_type" in content;
              const isNectar = content.content_format === ContentFormat.NECTAR;
              const isVideo = content.content_format === ContentFormat.VIDEO;

              if (isArticle) {
                return (
                  <ArticleCard
                    key={content._id}
                    article={content}
                    analyticsListName={AnalyticsItemList.PLAYLIST_PAGE} // TODO: Change analytics
                  />
                );
              }

              if (isNectar) {
                return (
                  <NectarCard
                    key={content._id}
                    nectar={content}
                    analyticsListName={AnalyticsItemList.HOME} // TODO: Change analytics
                  />
                );
              }

              if (isVideo) {
                return (
                  <VideoCard
                    key={content._id}
                    video={content}
                    analyticsListName={AnalyticsItemList.HOME} // TODO: Change analytics
                  />
                );
              }
            })}
          </ItemList>
        </RelatedContentWrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 92dvh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const InfographicContainer = styled.div`
  box-sizing: border-box;
  flex: 1;
  overflow-y: auto;
  display: flex;
  margin-top: 54px;

  img {
    width: 100%;
    height: auto;
  }
`;

const RelatedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 8px calc(16px + var(--safe-area-bottom));
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(
    180deg,
    #ff699c 0%,
    #fe5763 26.99%,
    #ff8a00 51.99%,
    #fdb955 77.51%,
    #ffc408 100%
  );
  position: relative;
  z-index: 2;
  margin-top: 80dvh;

  h2 {
    font: "Inter";
    font-weight: 900;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
  }

  .handle {
    width: 32px;
    height: 4px;
    border-radius: 20px;
    background: #ffffff;
    margin: 20px 0px 0px 0px;
  }
`;
