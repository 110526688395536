import { Link } from "react-router-dom";
import { ArticleModel } from "../../../domains/article/article.types";
import { t } from "i18next";
import { shortenText } from "../../../tools/utils";
import styled from "styled-components";
import { ReactComponent as PaperscrollIcon } from "../../../assets/icons/gpt/gpt-paperscroll.svg";

export default function GPTChatSources({ sources, onClickSeeAll }: {
  sources: ArticleModel[];
  onClickSeeAll: Function;
}) {
  if (!sources.length) return null;

  return (
    <Container>
      <Heading>
        <PaperscrollIcon />
        {t("common:sources")} ({sources.length})
        <span className="see-all" onClick={() => onClickSeeAll()}>{t("common:action.seeAll")}</span>
      </Heading>
      <Scroller className="scrollbar-hidden">
        {sources.map((source: ArticleModel, index: number) => {
          return (
            <Link key={index} to={`/post/${source.slug}`} style={{ display: "flex" }}>
              <SourceCard>
                <span className="title">{shortenText(source.title, 40)}</span>
                {(source.journal && source.journal.name) && ( // NOTE: Fix to hide non-populated journals. Remove later.
                  <div className="journal">
                    {source.journal.image && <img src={source.journal.image.url} alt={source.journal.name} />}
                    <span>{source.journal.name}</span>
                  </div>
                )}
              </SourceCard>
            </Link>
          );
        })}
      </Scroller>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Heading = styled.div`
  padding: 0 16px;
	display: inline-flex;
	align-items: center;
	gap: 8px;

	color: #212121;
	font-family: Inter;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 123.81% */
	letter-spacing: -0.21px;

  .see-all {
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px; /* 185.714% */
    letter-spacing: -0.14px;
  }
`;

const Scroller = styled.div`
  padding: 0 16px;
  overflow-x: auto;
  display: flex;
	gap: 8px;
`;

const SourceCard = styled.div`
  box-sizing: border-box;
  min-width: 186px;
  width: 186px;
  padding: 16px 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  background: var(--BRAND-CORE-White, #FFF);
  color: #212121;

  .title: {
    font-family: Inter;
    font-size: 14px;
    line-height: 26px; /* 185.714% */
  }

  .journal {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: Inter;
    font-size: 8px;

    img {
      width: 24px;
      height: 24px;
    }
  }
`;