import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { SpecialtyModel } from "../../domains/app/app.types";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { getNectarHighlights } from "../../domains/nectar/endpoints/getNectarHighlights";
import { getNectarsList } from "../../domains/nectar/endpoints/getNectarsList";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { filterArrayDuplicates } from "../../domains/app/utils/filterArrayDuplicates";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import Loader from "../../components/Loader";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavHome, NavLang } from "../../components/app/headers/layout";
import ClampLines from "../../components/app/ClampLines";
import SpecialtyBadges from "../../components/app/SpecialtyBadges";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import disableScroll from "disable-scroll";
import {
  GPTBottomScroller,
  GPTPageLayout,
  GPTScrollBadge,
} from "../../components/gpt/layout";
import GPTNav from "../../components/gpt/GPTNav";
import GPTDisclaimer from "../../components/gpt/GPTDisclaimer";

export default function GPTDiscoverPage() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { activeLang, t } = useLangContext();
  const { currentUser } = useCurrentUser();
  const [isDisclaimerVisible, setDisclaimerVisible] = useState(false);
  const [specialtyFilter, setSpecialtyFilter] = useState(params.get("specialty") ?? "");
  const [highlights, setHighlights] = useState<NectarModel[]>([]);
  const [nectars, setNectars] = useState<NectarModel[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off(); // Enable Scroll

        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.juisciGPT"),
          memorizePath: true,
        });
        if (isRedirected) return;

        const acceptedJuisciGPTRules = localStorage.getItem("acceptedJuisciGPTRules") ?? false;
        if (!acceptedJuisciGPTRules) setDisclaimerVisible(true);

        loadNectars();
      } catch (error) {
        console.error("Couldn't mount.", error);
        displayToast(t("error:default"));
        history.replace("/search");
      }
    })();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSpecialtyFilter(params.get("specialty") ?? "");
  }, [location.search]);

  useEffect(() => {
    if (currentUser) loadNectars();
  }, [currentUser, activeLang, specialtyFilter]);

  async function loadNectars() {
    try {
      setLoading(true);

      if (!specialtyFilter) {
        if (highlights.length === 0) {
          const docs = await getNectarHighlights({
            language: activeLang,
          });
          setHighlights(docs);
        }
      } else {
        const { docs } = await getNectarsList({
          limit: 10,
          offset: 0,
          language: activeLang,
          medicalSpecialties: specialtyFilter,
        });
        setNectars(docs);
      }
    } catch (error) {
      console.error("Couldn't load nectars.", error);
      displayToast(t("error:default"));
    } finally {
      setLoading(false);
    }
  }

  function handleChangeFilter(slug: string) {
    let query = "";
    if (slug) {
      const params = new URLSearchParams();
      params.append("specialty", slug);
      query = `?${params.toString()}`;
    }
    history.replace(`/gpt/discover${query}`);
  }

  function renderInnerContent() {
    if (isLoading) return <Loader loaderOnly />;

    const list = !specialtyFilter ? highlights : nectars;

    return (
      <GPTNectarGrid>
        {(list ?? []).map((nectar: NectarModel) => {
          // TODO: Restore.
          // let displayedSpecialty;
          // if (nectar.medical_specialties) {
          //   displayedSpecialty = nectar.medical_specialties.find(
          //     (spe: SpecialtyModel) => spe._id === specialtyFilter
          //   ) ?? nectar.medical_specialties[0 as unknown as number];
          // }

          return (
            <GPTNectarCard
              key={nectar._id}
              onClick={() => history.push(`/gpt/discover/${nectar.slug}`)}
            >
              <SpecialtyBadges specialties={nectar.medical_specialties?.slice(0, 1) ?? []} />
              <ClampLines className="title" lines={10}>{nectar.question}</ClampLines>
            </GPTNectarCard>
          );
        })}
      </GPTNectarGrid>
    );
  }

  if (!currentUser) return <Loader />;

  return (
    <>
      <GPTPageLayout>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavHome /></HeaderLinks>
          <HeaderTitle>{t("juisciGpt:discover.title")}</HeaderTitle>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <div className="page-wrapper">
          {renderInnerContent()}
        </div>

        <GPTBottomScroller className="scrollbar-hidden">
          <div className="contents">
            <GPTScrollBadge
              className={specialtyFilter === "" ? "active" : ""}
              onClick={() => handleChangeFilter("")}
            >
              {t("content:nectarPage.mostPopular", { lng: activeLang })}
            </GPTScrollBadge>
            {filterArrayDuplicates(
              [currentUser?.main_specialty, ...currentUser?.medical_specialties],
              (a: SpecialtyModel, b: SpecialtyModel) => a.uid === b.uid,
            ).map(
              (specialty) => {
                if (!specialty?.translations) return null;
                return (
                  <GPTScrollBadge
                    key={specialty._id}
                    className={
                      specialtyFilter === specialty?._id ? "active" : ""
                    }
                    onClick={() => handleChangeFilter(specialty._id)}
                  >
                    {getItemTranslation(specialty)}
                  </GPTScrollBadge>
                );
              }
            )}
          </div>
        </GPTBottomScroller>

        <GPTNav />
      </GPTPageLayout>

      <GPTDisclaimer visible={isDisclaimerVisible} />
    </>
  );
}

const GPTNectarGrid = styled.div`
  padding: 20px 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
`;

const GPTNectarCard = styled.div`
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px;
  background-color: #ffc408;
  animation: fadeIn ease-in 0.1s;

  &:nth-child(3n + 1) {
    background-color: #ffc408;
  }

  &:nth-child(3n + 2) {
    background-color: #ffffff;
  }

  &:nth-child(3n + 3) {
    background-color: #ce0868;
    .title {
      color: #ffffff;
    }
  }

  .--specialty-tag, .specialty-badge {
    width: min-content;
    /* NOTE: Fix for badge on small cards */
    max-width: calc(100% - 15px);
    border-radius: 10px !important;
    white-space: normal;
    
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #212121;
  }
`;
