import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { useSearchAll } from "../../domains/search/hooks/useSearchAll";
import FadeIn from "react-fade-in/lib/FadeIn";
import SkeletonContentCard from "../profile/SkeletonContentCard";
import Loader from "../Loader";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { SearchResultsListWrapper, SearchResultsSection, ViewButton } from "./layout";
import { t } from "i18next";
import JournalButton from "../JournalButton";
import UserButton from "../app/UserButton";
import ArticleCard from "../cards/ArticleCard";
import PlaylistCard from "../playlists/PlaylistCard";
import NectarCard from "../cards/NectarCard";
import VideoCard from "../cards/VideoCard";
import SearchNoResults from "./SearchNoResults";
import { SearchCategory } from "../../domains/search/search.types";

const { ALL, ARTICLES, JOURNALS, PLAYLISTS, USERS, VIDEOS, NECTARS } = SearchCategory;

export default function SearchResultsAll() {
  const history = useHistory();
  const location = useLocation();
  const selectedContextTab = useAppSelector((state) => state.search.selectedContextTab);
  const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
  const searcher = useSearchAll(searchValueParam);

  return (
    <>
      {searcher.isLoadingArticles && (
        <SearchResultsSection>
          <FadeIn>
            <SearchResultsListWrapper>
              {[...Array(10)].map((_, index) => <SkeletonContentCard key={index} />)}
            </SearchResultsListWrapper>
          </FadeIn>
        </SearchResultsSection>
      )}

      {(!!searcher.articles.length && [ALL, ARTICLES].includes(selectedContextTab)) &&
        <SearchResultsSection>
          <h3>{t("content:type.publications")}</h3>
          <FadeIn>
            <SearchResultsListWrapper>
              {searcher.articles.map((article: any, index: number) => {
                return (
                  <ArticleCard
                    key={index}
                    article={article}
                    analyticsListName={AnalyticsItemList.SEARCH}
                  />
                );
              })}
            </SearchResultsListWrapper>
            {(searcher.articles.length < searcher.totalArticles) &&
              <ViewButton onClick={() => history.push(`/search/articles?value=${searchValueParam}`)}>
                {t("common:action.viewAll")} ({searcher.totalArticles})
              </ViewButton>
            }
          </FadeIn>
        </SearchResultsSection>
      }

      {searcher.isLoadingJournals && (
        <SearchResultsSection>
          <Loader loaderOnly />
        </SearchResultsSection>
      )}

      {(!!searcher.journals.length && [ALL, JOURNALS].includes(selectedContextTab)) &&
        <SearchResultsSection>
          <h3>{t("content:type.journals")}</h3>
          <FadeIn>
            <SearchResultsListWrapper>
              {searcher.journals.map((journal: any) => {
                return (
                  <JournalButton
                    key={journal._id}
                    journal={journal}
                    onClick={() => history.push(`/journal/${journal.uid}`)}
                  />
                );
              })}
            </SearchResultsListWrapper>
            {(searcher.journals.length < searcher.totalJournals) &&
              <ViewButton onClick={() => history.push(`/search/journals?value=${searchValueParam}`)}>
                {t("common:action.viewAll")} ({searcher.totalJournals})
              </ViewButton>
            }
          </FadeIn>
        </SearchResultsSection>
      }

      {searcher.isLoadingPlaylists && (
        <SearchResultsSection>
          <Loader loaderOnly />
        </SearchResultsSection>
      )}

      {(!!searcher.playlists.length && [ALL, PLAYLISTS].includes(selectedContextTab)) &&
        <SearchResultsSection>
          <h3>{t("content:type.playlists")}</h3>
          <FadeIn>
            <SearchResultsListWrapper>
              {searcher.playlists.map((playlist: any) => {
                return (
                  <PlaylistCard
                    key={playlist.id}
                    playlist={playlist}
                  />
                );
              })}
            </SearchResultsListWrapper>
            {(searcher.playlists.length < searcher.totalPlaylists) &&
              <ViewButton onClick={() => history.push(`/search/playlists?value=${searchValueParam}`)}>
                {t("common:action.viewAll")} ({searcher.totalPlaylists})
              </ViewButton>
            }
          </FadeIn>
        </SearchResultsSection>
      }

      {searcher.isLoadingUsers && (
        <SearchResultsSection>
          <Loader loaderOnly />
        </SearchResultsSection>
      )}

      {(!!searcher.users.length && [ALL, USERS].includes(selectedContextTab)) &&
        <SearchResultsSection>
          <h3>{t("common:users")}</h3>
          <FadeIn>
            {searcher.users.map((listUser: any) => {
              return (
                <UserButton
                  key={listUser._id}
                  user={listUser}
                  onClick={() => {
                    history.push(`/profile/user/${listUser?.uid}`);
                  }}
                />
              );
            })}
            {(searcher.users.length < searcher.totalUsers) &&
              <ViewButton onClick={() => history.push(`/search/users?value=${searchValueParam}`)}>
                {t("common:action.viewAll")} ({searcher.totalUsers})
              </ViewButton>
            }
          </FadeIn>
        </SearchResultsSection>
      }

      {searcher.isLoadingVideos && (
        <SearchResultsSection>
          <Loader loaderOnly />
        </SearchResultsSection>
      )}


      {(!!searcher.videos.length && [ALL, VIDEOS].includes(selectedContextTab)) &&
        <SearchResultsSection>
          <h3>{t("content:type.videos")}</h3>
          <FadeIn>
            <SearchResultsListWrapper>
              {searcher.videos.map((video: any, index: number) => {
                return (
                  <VideoCard
                    key={video._id}
                    video={video}
                    analyticsListName={AnalyticsItemList.SEARCH}
                  />
                );
              })}
            </SearchResultsListWrapper>
            {(searcher.videos.length < searcher.totalVideos) &&
              <ViewButton onClick={() => history.push(`/search/videos?value=${searchValueParam}`)}>
                {t("common:action.viewAll")} ({searcher.totalVideos})
              </ViewButton>
            }
          </FadeIn>
        </SearchResultsSection>
      }

      {searcher.isLoadingNectars && (
        <SearchResultsSection>
          <Loader loaderOnly />
        </SearchResultsSection>
      )}

      {(!!searcher.nectars.length && [ALL, NECTARS].includes(selectedContextTab)) &&
        <SearchResultsSection>
          <h3>{t("content:type.nectarsOfScience")}</h3>
          <FadeIn>
            <SearchResultsListWrapper>
              {searcher.nectars.map((nectar: any) => {
                return (
                  <NectarCard
                    key={nectar.id}
                    nectar={nectar}
                    analyticsListName={AnalyticsItemList.SEARCH}
                    hideOrganization
                  />
                );
              })}
            </SearchResultsListWrapper>
            {(searcher.nectars.length < searcher.totalNectars) &&
              <ViewButton onClick={() => history.push(`/search/nectars?value=${searchValueParam}`)}>
                {t("common:action.viewAll")} ({searcher.totalNectars})
              </ViewButton>
            }
          </FadeIn>
        </SearchResultsSection>
      }

      {!!searchValueParam.length && !searcher.isAnyLoading && searcher.totalAll === 0 && (
        <FadeIn delay={200}>
          <div style={{
            textAlign: "center",
            padding: "21px 16px",
          }}>
            <SearchNoResults />
          </div>
        </FadeIn>
      )}
    </>
  );
}