import { useCallback, useMemo, useState } from "react";

export function useOnScreen() {
  const [isIntersecting, setIntersecting] = useState(false);
  const [current, setCurrent] = useState<HTMLElement | null>(null);
  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), []);

  const ref = useCallback((node) => {
    if (node) observer.observe(node);
    setCurrent(node);
    return () => observer.disconnect();
  }, []);

  return {
    ref,
    current,
    isIntersecting,
  };
}
