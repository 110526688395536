import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { SpecialtyModel } from "../../domains/app/app.types";
import { ArticleModel } from "../../domains/article/article.types";
import { VideoFormat } from "../../domains/video/video.types";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { getArticleBySlug } from "../../domains/article/endpoints/getArticleBySlug";
import { getRelatedArticles } from "../../domains/article/endpoints/getRelatedArticles";
import { synthesizeArticleSpeech } from "../../domains/article/endpoints/synthesizeArticleSpeech";
import { viewArticle } from "../../domains/article/endpoints/viewArticle";
import { readArticle } from "../../domains/article/endpoints/readArticle";
import { viewArticleSource } from "../../domains/article/endpoints/viewArticleSource";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useLikeArticle } from "../../domains/user/hooks/useLikeArticle";
import { useSaveArticle } from "../../domains/user/hooks/useSaveArticle";
import { useShareArticle } from "../../domains/user/hooks/useShareArticle";
import { useArticleSwipe } from "../../domains/article/hooks/useArticleSwipe";
import { useOnScreen } from "../../domains/app/hooks/useOnScreen";
import styled from "styled-components";
import { Trans } from "react-i18next";
import {
  AUDIO_PERCENTAGES,
  AnalyticsItemList,
} from "../../tools/analytics/analytics.types";
import {
  gaEventArticleAudioComplete,
  gaEventArticleAudioProgress,
  gaEventArticlePlayAudio,
  gaEventArticleViewOriginalPaper,
  gaEventViewItemArticle,
} from "../../tools/analytics/articleAnalytics";
import { HapticEffect, SupportedLanguage } from "../../interfaces";
import {
  iosGenerateHaptic,
  iosSetBackgroundColor,
  isNativeIOS,
} from "../../tools/ios";
import { renderDate } from "../../domains/app/utils/renderDate";
import { getArticleTypeText } from "../../domains/app/utils/getArticleTypeText";
import { ArticleTypeHeading, RoundIcon } from "../../components/global";
import SpecialtyBadges from "../../components/app/SpecialtyBadges";
import ExpandableText from "../../components/app/ExpandableText";
import CustomIcon from "../../components/CustomIcon";
import Loader from "../../components/Loader";
import ImageViewer, {
  CustomIndicators,
} from "../../components/post/ImageViewer";
import GestureDetector from "../../components/GestureDetector";
import ArticleCard from "../../components/cards/ArticleCard";
import PlaylistPanel from "../../components/app/PlaylistPanel";
import ContentExternalLink from "../../components/ContentExternalLink";
import PostAudioPlayer from "../../components/media/PostAudioPlayer";
import { Carousel } from "react-responsive-carousel";
import disableScroll from "disable-scroll";
import { displayToast } from "../../components/app/AppToast";
import { ReactComponent as BookmarkIcon } from "../../assets/icons/bookmark-fill.svg";
import { ReactComponent as LikeIcon } from "../../assets/icons/like-fill.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/send-fill.svg";
import { ReactComponent as CloseAltIcon } from "../../assets/icons/close_alt.svg";
import { ReactComponent as StrawberryIcon } from "../../assets/icons/straw.svg";
import { ReactComponent as GrapesIcon } from "../../assets/icons/grapes.svg";
import { ReactComponent as AppleIcon } from "../../assets/icons/apple.svg";
import { ReactComponent as RaspberryIcon } from "../../assets/icons/rasp.svg";
import { ReactComponent as CherryIcon } from "../../assets/icons/cherry.svg";
import { ReactComponent as PineappleIcon } from "../../assets/icons/pineapple.svg";
import { ReactComponent as LemonIcon } from "../../assets/icons/lemon.svg";
import { ReactComponent as BlenderIcon } from "../../assets/icons/blender.svg";
import { ReactComponent as AvocadoIcon } from "../../assets/icons/avocado.svg";
import { ReactComponent as PeachIcon } from "../../assets/icons/peach.svg";
import { ReactComponent as AuthorIcon } from "../../assets/icons/user-fill.svg";

export default function PostPage() {
  // Router
  const history = useHistory();
  const location = useLocation<{
    sponsorised: boolean;
    playlist: PlaylistModel;
    article?: ArticleModel;
  }>();
  const params = useParams<{ slug: string }>();
  // Store
  const { swipeList, swipeIndex, setSwipeIndex, clearSwipeCache } =
    useArticleSwipe();
  // State
  const [isLoading, setLoading] = useState(true);
  const [article, setArticle] = useState<ArticleModel | null>(null);
  const [relatedArticles, setRelatedArticles] = useState<ArticleModel[]>([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isArticleRead, setArticleRead] = useState(false);
  const [isSourceViewed, setSourceViewed] = useState(false);
  const [isBackgroundExpanded, setBackgroundExpanded] = useState(false);
  const [isMethodologyExpanded, setMethodologyExpanded] = useState(false);
  const [isLimitationsExpanded, setLimitationsExpanded] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const [isWaitingActionResult, setWaitingActionResult] = useState(false);
  const [memLiked, setMemLiked] = useState<boolean | null>(null);
  const [memSaved, setMemSaved] = useState<boolean | null>(null);
  const [wasShared, setWasShared] = useState(false);
  const [isShareHighlighted, setShareHighlighted] = useState(false);
  const [isAudioPlaying, setAudioPlaying] = useState(false);
  // Refs
  const dummyTopRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  // Custom
  const { activeLang, t } = useLangContext();
  const isHeaderOnScreen = headerRef.current
    ? window.scrollY < headerRef.current?.clientHeight
    : true;
  const { isLiked, toggleLike } = useLikeArticle(
    article,
    AnalyticsItemList.ARTICLE_PAGE
  );
  const { isSaved, toggleSaveToPlaylists } = useSaveArticle(
    article,
    AnalyticsItemList.ARTICLE_PAGE
  );
  const shareArticle = useShareArticle(article, AnalyticsItemList.ARTICLE_PAGE);
  const articleFirstSpecialty = article
    ?.medical_specialties?.[0] as SpecialtyModel;
  // NOTE: These values are needed in real time for analytics or events out of
  // the React lifecycle. React state doesn't work here.
  const articleRef = useRef<any>(null);
  const trackDurationRef = useRef<number>(0);
  const { isIntersecting: isFiguresOnScreen, ref: figuresRef } = useOnScreen();

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        window.scrollTo(0, 0);
        onPageScrollListen();
        window.addEventListener("scroll", onPageScrollListen);

        if (localStorage.getItem("_nb_views_tm") === null) {
          localStorage.setItem("numberViews", "0");
        }
        localStorage.setItem("_nb_views_tm", JSON.stringify(new Date()));

        const isAuthentified = await isUserAuthentified();

        if (!isAuthentified) {
          const isRedirected = preflightGuest();
          if (isRedirected) return;
        }

        await loadArticle();
      } catch (error) {
        console.error("Couldn't mount.", error);
        displayToast(t("error:default"));
      }
    })();

    return () => {
      window.removeEventListener("scroll", onPageScrollListen);
    };
  }, [params.slug]);

  useEffect(() => {
    if (article) {
      (async function () {
        try {
          await viewArticle(article._id);
          gaEventViewItemArticle({
            article,
            listName: AnalyticsItemList.ARTICLE_PAGE,
          });
          checkAudioExists();
        } catch (error) {
          console.error("Couldn't view article.", error);
        }
      })();
    }
  }, [article]);

  useEffect(() => {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);

    if (isAudioPlaying) {
      gaEventArticlePlayAudio({
        article: article as ArticleModel,
        listName: AnalyticsItemList.ARTICLE_PAGE,
      });
    }
  }, [isAudioPlaying]);

  useEffect(() => {
    if (isFiguresOnScreen) handleReadArticle();
  }, [isFiguresOnScreen]);

  function preflightGuest() {
    const numberViews = localStorage.getItem("numberViews");

    if (numberViews && parseInt(numberViews) >= 1) {
      localStorage.setItem("authRedirectPath", location.pathname);
      history.replace("/login/prev");
      return true;
    }

    localStorage.setItem(
      "numberViews",
      numberViews !== null ? JSON.stringify(parseInt(numberViews) + 1) : "1"
    );
    return false;
  }

  async function loadArticle() {
    try {
      let cachedArticle: ArticleModel | null = null;

      // Check location state
      if (location?.state?.article) {
        cachedArticle = location.state.article;
      }

      // Check swipe list
      if (!cachedArticle && swipeList.length) {
        const swipeListSlugs = swipeList.map(
          (article: ArticleModel) => article.slug
        );
        const slugIndex = swipeListSlugs.findIndex(
          (slug: string) => slug === params.slug
        );
        if (slugIndex !== -1) {
          setSwipeIndex(slugIndex); // Restore swipe action if index was lost
          cachedArticle = swipeList[slugIndex];
        } else {
          clearSwipeCache(); // Clear swipe index and prevent swipe action
        }
      }

      if (cachedArticle) setArticle(cachedArticle);

      // Display loader while fetching
      setLoading(!cachedArticle);

      // NOTE: Execute fetch request to increment view metrics.
      // TODO: Not necessary anymore.
      const fetchedArticle = await getArticleBySlug(params.slug);
      setArticle(fetchedArticle);
      articleRef.current = article;
      setLoading(false);

      if (article) await loadRelatedArticles(article);
    } catch (error) {
      console.error("Couldn't fetch article.", error);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        displayToast(t("toast:fail.articleNotFound"), "error");
        history.replace("/discovery");
      } else {
        throw error;
      }
      return null;
    }
  }

  async function loadRelatedArticles(sourceArticle: ArticleModel) {
    try {
      if (location?.state?.sponsorised) {
        setRelatedArticles(
          location?.state?.playlist?.playlist.filter((el: any) => {
            return el._id !== article?._id;
          })
        );
        return;
      }
      const data = await getRelatedArticles(
        params.slug,
        (sourceArticle?.medical_specialties as SpecialtyModel[])?.[0]?.uid
      );
      setRelatedArticles(data);
    } catch (error) {
      console.error("Couldn't get related articles.", error);
    }
  }

  async function checkAudioExists() {
    try {
      if (!article)
        throw new Error("No article. Can't check speech or synthesize.");
      if (!article.speech || !article.speech?.url) {
        const { speech } = await synthesizeArticleSpeech(
          article.slug,
          activeLang
        );
        setArticle({ ...article, speech });
      }
    } catch (error) {
      console.error("Couldn't synthesize article speech.", error);
    }
  }

  function getMetricBonus(currentValue: boolean, memValue: boolean | null) {
    if (memValue !== null && currentValue !== memValue) {
      return !!currentValue ? 1 : -1;
    }
    return 0;
  }

  function processChunksOnExit(playedChunks: number[][]) {
    let totalPlayedDuration = 0;
    for (const [a, b] of playedChunks) {
      totalPlayedDuration += b - a;
    }

    let percentage = 0;

    if (trackDurationRef.current) {
      const playedRatio =
        (totalPlayedDuration / trackDurationRef.current) * 100;
      for (const step of AUDIO_PERCENTAGES) {
        if (playedRatio < step) break;
        percentage = step;
      }

      if (percentage) {
        gaEventArticleAudioProgress({
          article: articleRef.current,
          listName: AnalyticsItemList.ARTICLE_PAGE,
          percentage,
        });
      }
    }
  }

  // NOTE: Uses default event handler. React state isn't read here.
  function onPageScrollListen() {
    const progress = (window.innerHeight + window.scrollY) / document.documentElement.scrollHeight;
    setScrollProgress(progress);
  }

  function handleReadArticle() {
    if (article && !isArticleRead) {
      readArticle(article._id);
      setArticleRead(true);
    }
  }

  function handleViewSource() {
    if (article && !isSourceViewed) {
      viewArticleSource(article._id);
      setSourceViewed(true);
    }
  }

  function handleExit() {
    iosGenerateHaptic(HapticEffect.SELECTION);
    // setSwipeIndex(-1); // CHECK IF NECESSARY?
    const searchParams = new URLSearchParams(location.search);
    if (location.key && !searchParams.get("notificationCenter"))
      history.goBack();
    else {
      history.replace("/discovery");
    }
  }

  async function handleSave({
    add,
    remove,
  }: {
    add: string[];
    remove: string[];
  }) {
    if (memSaved === null) setMemSaved(isSaved);
    await toggleSaveToPlaylists({ add, remove });

    if (!isSaved) {
      // If a save was just done
      setTimeout(() => {
        setShareHighlighted(true);
        setTimeout(() => setShareHighlighted(false), 1500);
      }, 1000);
    }
  }

  async function handleLike(e: SyntheticEvent) {
    if (isWaitingActionResult) return;

    if (memLiked === null) setMemLiked(isLiked);

    setWaitingActionResult(true);
    await toggleLike();
    setWaitingActionResult(false);

    if (!isLiked) {
      // If a like just happened
      setShareHighlighted(true);
      setTimeout(() => setShareHighlighted(false), 1500);
    }
  }

  async function handleShare() {
    shareArticle();
    setWasShared(true);
  }

  function handleNavigatePrevious() {
    if (swipeList.length > 0 && swipeIndex !== -1 && swipeIndex > 0) {
      const prevArticle = swipeList[swipeIndex - 1];
      history.replace(`/post/${prevArticle.slug}`);
      setSwipeIndex(swipeIndex - 1);
    }
  }

  function handleNavigateNext() {
    if (
      swipeList.length > 0 &&
      swipeIndex !== -1 &&
      swipeIndex < swipeList.length - 1
    ) {
      const nextArticle = swipeList[swipeIndex + 1];
      history.replace(`/post/${nextArticle.slug}`);
      setSwipeIndex(swipeIndex + 1);
    }
  }

  return (
    <>
      {/* NOTE: Left here in case header comes back. */}
      <div ref={dummyTopRef} />

      {isLoading ? (
        <Loader />
      ) : (
        <GestureDetector
          onSwipeLeft={handleNavigateNext}
          onSwipeRight={handleNavigatePrevious}
        >
          <Wrapper>
            <Header ref={headerRef}>
              <div className="top">
                <div>
                  <time>{renderDate(article?.publication_date as Date)}</time>
                  <div className="badges">
                    <SpecialtyBadges
                      specialties={
                        article?.medical_specialties
                          ?.slice(0, 4)
                          // NOTE: hotfix for corrupted data, remove later.
                          .filter((el) => !!el) ?? []
                      }
                    />
                  </div>
                </div>
                {isHeaderOnScreen && <CloseAltIcon onClick={handleExit} />}
              </div>

              <div>
                <ArticleTypeHeading>
                  {t(getArticleTypeText(article as ArticleModel))}
                </ArticleTypeHeading>
                <h1>{article?.title}</h1>
              </div>
            </Header>

            <StickyHeader
              className={!isHeaderOnScreen ? "floating" : ""}
              style={{
                top: dummyTopRef.current?.clientHeight ?? 0,
              }}
            >
              {!isHeaderOnScreen && (
                <>
                  <div
                    style={{
                      height: "var(--safe-area-top)",
                      background: "#ecf0f5",
                    }}
                  />
                  <ProgressBar progress={scrollProgress} />
                </>
              )}
              <ActionsContainer>
                <ActionItem>
                  <ActionItemWrapper
                    onClick={() => setPlaylistsPanelVisible(true)}
                    style={{ background: isSaved ? "#FFC408" : "#90A4AE" }}
                  >
                    <BookmarkIcon
                      style={{
                        color: "#FFFFFF",
                      }}
                    />
                  </ActionItemWrapper>
                  <span>
                    {Math.max(
                      0,
                      (article?.metrics?.saveds ?? 0) +
                      getMetricBonus(isSaved, memSaved)
                    )}
                  </span>
                </ActionItem>

                <ActionItem>
                  <ActionItemWrapper
                    onClick={handleLike}
                    style={{ background: isLiked ? "#FF8800" : "#90A4AE" }}
                  >
                    <LikeIcon
                      style={{
                        color: "#FFFFFF",
                      }}
                    />
                  </ActionItemWrapper>
                  <span>
                    {Math.max(
                      0,
                      (article?.metrics?.likes ?? 0) +
                      getMetricBonus(isLiked, memLiked)
                    )}
                  </span>
                </ActionItem>

                <ActionItem>
                  <ActionItemWrapper onClick={handleShare}>
                    <ShareAnimation active={isShareHighlighted}>
                      <ShareIcon />
                    </ShareAnimation>
                  </ActionItemWrapper>
                  <span>
                    {Math.max(
                      0,
                      (article?.metrics?.shares ?? 0) + (wasShared ? 1 : 0)
                    )}
                  </span>
                </ActionItem>

                <BigActionsContainer>
                  <div
                    className={`${isHeaderOnScreen ? "big-actions" : "small-actions"
                      }`}
                  >
                    <PostAudioPlayer
                      trackUrl={article?.speech?.url ?? null}
                      onLoad={(duration: number) => {
                        trackDurationRef.current = duration;
                      }}
                      onPlay={() => setAudioPlaying(true)}
                      onPause={() => setAudioPlaying(false)}
                      onEndReached={() => {
                        gaEventArticleAudioComplete({
                          article: article as ArticleModel,
                          listName: AnalyticsItemList.ARTICLE_PAGE,
                        });
                      }}
                      onUnmount={({
                        playedChunks,
                      }: {
                        playedChunks?: number[][];
                      }) => {
                        if (playedChunks) processChunksOnExit(playedChunks);
                      }}
                      numberOfTracks={swipeList.length}
                      trackIndex={swipeIndex}
                      onPrevious={handleNavigatePrevious}
                      onNext={handleNavigateNext}
                      resumeOnTrackChange={isAudioPlaying}
                    />

                    {isHeaderOnScreen ? (
                      <>
                        {article?.video && (
                          <VideoIcon
                            scale={1.2}
                            onClick={() =>
                              history.push(
                                article.video.videoFormat === VideoFormat.STORY
                                  ? `/video/story/${article.video.slug}`
                                  : `/video/${article.video.slug}`
                              )
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="end-icons">
                        {article?.video ? (
                          <VideoIcon
                            onClick={() =>
                              history.push(
                                article.video.videoFormat === VideoFormat.STORY
                                  ? `/video/story/${article.video.slug}`
                                  : `/video/${article.video.slug}`
                              )
                            }
                          />
                        ) : (
                          <div />
                        )}
                        <CloseAltIcon onClick={handleExit} />
                      </div>
                    )}
                  </div>
                </BigActionsContainer>
              </ActionsContainer>
            </StickyHeader>

            <JournalLink
              to={"/journal/" + article?.journal?.uid}
              onClick={() => {
                gaEventArticleViewOriginalPaper({
                  article: article as ArticleModel,
                  listName: AnalyticsItemList.ARTICLE_PAGE,
                });
              }}
            >
              <span className="from">{t("content:articlePage.originalPublicationFrom")}</span>
              <span className="journal-name">
                {article?.journal?.name}
                <CustomIcon iconName="link" />
              </span>
            </JournalLink>

            <ContentWrapper>
              <ContentPartWrapper>
                {!!article?.conclusion?.length && (
                  <section>
                    <h2>
                      <LemonIcon />
                      {t("content:articlePage.section.conclusion")}
                    </h2>
                    <ul>
                      {article?.conclusion
                        ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((el, index) => (
                          <li key={index}>{el}</li>
                        ))}
                    </ul>
                  </section>
                )}

                {article?.background && (
                  <section>
                    <h2>
                      <GrapesIcon />
                      {t("content:articlePage.section.background")}
                    </h2>
                    <ExpandableText
                      isExpanded={isBackgroundExpanded}
                      setExpand={setBackgroundExpanded}
                    >
                      {article?.background}
                    </ExpandableText>
                  </section>
                )}

                {article?.objectives && (
                  <section>
                    <h2>
                      <PineappleIcon />
                      {t("content:articlePage.section.objectives")}
                    </h2>
                    <ul>
                      {article?.objectives
                        ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((el, index) => (
                          <li key={index}>{el}</li>
                        ))}
                    </ul>
                  </section>
                )}

                {!!article?.images?.length ? (
                  <section ref={figuresRef}>
                    <h2 style={{ alignItems: "flex-start" }}>
                      <AppleIcon />
                      {t("content:articlePage.section.figures")}
                    </h2>
                  </section>
                ) : (
                  <section style={{ padding: 0 }} />
                )}
              </ContentPartWrapper>

              {/* NOTE: Break content flow to make image carousel work. */}
              {!!article?.images?.length && (
                <ImageCarousel
                  images={
                    article?.images?.filter((el) => !!el) ?? // NOTE: hotfix for corrupted data, remove later.
                    []
                  }
                />
              )}

              <ContentPartWrapper>
                <section style={{ padding: 0 }} />
                {article?.methodology && (
                  <section>
                    <h2>
                      <RaspberryIcon />
                      {t("content:articlePage.section.methodology")}
                    </h2>
                    <ExpandableText
                      isExpanded={isMethodologyExpanded}
                      setExpand={setMethodologyExpanded}
                    >
                      {article?.methodology}
                    </ExpandableText>
                  </section>
                )}

                {article?.results && (
                  <section>
                    <h2>
                      <CherryIcon />
                      {t("content:articlePage.section.results")}
                    </h2>
                    <ul>
                      {article?.results
                        ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((el, index) => (
                          <li key={index}>{el}</li>
                        ))}
                    </ul>
                  </section>
                )}

                {article?.limitations && (
                  <section>
                    <h2>
                      <AvocadoIcon />
                      {t("content:articlePage.section.limitations")}
                    </h2>
                    <ExpandableText
                      isExpanded={isLimitationsExpanded}
                      setExpand={setLimitationsExpanded}
                    >
                      {article?.limitations}
                    </ExpandableText>
                  </section>
                )}

                {article?.disclosure && (
                  <section>
                    <h2 style={{ alignItems: "flex-start" }}>
                      <PeachIcon />
                      {t("content:articlePage.section.disclosureStatement")}
                    </h2>
                    <p>{article?.disclosure}</p>
                  </section>
                )}

                {article?.primary_author && (
                  <section>
                    <h2>
                      <BlenderIcon />
                      {t("common:authors")}
                    </h2>
                    <AuthorList>
                      {[article.primary_author, ...article.authors]
                        .filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((author, index) => {
                          return (
                            <div className="author-item" key={index}>
                              <AuthorIcon />
                              <span>{author}</span>
                            </div>
                          );
                        })}
                    </AuthorList>
                  </section>
                )}

                {article?.keywords && (
                  <section>
                    <h2>
                      <StrawberryIcon />
                      {t("content:articlePage.section.keywords")}
                    </h2>
                    <p>
                      {article?.keywords
                        ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((keyword, index) => (
                          <span key={"keyword" + keyword + index}>
                            {keyword}
                            {index <
                              (article?.keywords?.length as number) - 1 && ", "}
                          </span>
                        ))}
                    </p>
                  </section>
                )}

                {article?.edito && (
                  <section>
                    <ContentExternalLink
                      href={article.edito}
                      title={<Trans i18nKey="content:articlePage.editorial.title" />}
                      text={<Trans i18nKey="content:articlePage.editorial.text" />}
                      isEditorial
                      onClick={handleViewSource}
                    />
                  </section>
                )}

                {article?.doi && (
                  <section>
                    <ContentExternalLink
                      href={article?.doi}
                      title={<Trans i18nKey="content:articlePage.externalLink.title" />}
                      text={<Trans i18nKey="content:articlePage.externalLink.text" />}
                      onClick={handleViewSource}
                    />
                  </section>
                )}
              </ContentPartWrapper>
            </ContentWrapper>

            {!!relatedArticles.length && (
              <RelatedArticlesWrapper>
                <h2>
                  {location?.state?.sponsorised ? (
                    `${t("content:type.playlist")} ${location?.state?.playlist?.company?.name
                    }'s playlist`
                  ) : (
                    <>
                      {t("content:articlePage.refill")}
                      <br />
                      {activeLang === SupportedLanguage.FR
                        ? "aeouiAEOUI".includes(
                          getItemTranslation(articleFirstSpecialty)[0]
                        )
                          ? "d'"
                          : "de "
                        : " "}
                      {getItemTranslation(articleFirstSpecialty)}
                    </>
                  )}
                </h2>
                <CardList>
                  {relatedArticles
                    ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                    .map((article) => (
                      <ArticleCard
                        key={article._id}
                        article={article}
                        analyticsListName={AnalyticsItemList.ARTICLE_PAGE}
                      />
                    ))}
                </CardList>
              </RelatedArticlesWrapper>
            )}
          </Wrapper>
        </GestureDetector>
      )}

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={article}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={handleSave}
      />
    </>
  );
}

function VideoIcon({ scale, onClick }: { scale?: number; onClick: Function }) {
  return (
    <RoundIcon
      style={{
        background: "linear-gradient(180deg, #FFD000 0%, #FFA100 100%)",
      }}
    >
      <CustomIcon
        iconName="videoPublication"
        className="video-icon"
        onClick={onClick}
        scale={scale || 1}
      />
    </RoundIcon>
  );
}

function ImageCarousel({ images }: { images: { _id: string; url: string }[] }) {
  const [isImageViewerOpen, setImageViewerOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <>
      {images?.length > 0 && (
        <>
          <div
            onClick={() => {
              setImageViewerOpen(true);
              disableScroll.on();
              if (isNativeIOS) iosSetBackgroundColor("#191919");
            }}
          >
            <Carousel
              autoFocus={false}
              autoPlay={false}
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={30}
              interval={1e12}
              stopOnHover={true}
              infiniteLoop={true}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              centerMode={false}
              selectedItem={imageIndex}
              onChange={(to) => setImageIndex(to)}
            >
              {images.map(
                (figure: { _id: string; url: string }, index: number) => (
                  <img
                    key={figure?._id + index}
                    src={figure?.url}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                )
              )}
            </Carousel>
          </div>
          <ImageViewer
            open={isImageViewerOpen}
            imgs={images}
            imageIndex={imageIndex}
            updateIndex={(to: number) => setImageIndex(to)}
            onClose={() => {
              setImageViewerOpen(false);
              disableScroll.off();
            }}
          />
          <div
            style={{
              width: "fit-content",
              margin: "15px auto",
            }}
          >
            {images?.map(
              (_, index) =>
                index < 14 && (
                  <CustomIndicators
                    key={"indicatorsImageView" + index}
                    active={imageIndex === index}
                  />
                )
            )}
          </div>
        </>
      )}
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  margin-bottom: 0;
  background: white;
  font-family: "Inter";
`;

const Header = styled.div`
  position: relative;
  padding: calc(40px + var(--safe-area-top)) 22px 22px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #ecf0f5;
  font-family: Inter;
  color: #212121;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 30px;
      min-width: 30px;
      height: 30px;
    }

    .badges {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  }

  time {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.48px;
  }

  h1 {
    margin: 10px 0 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.6px;
  }
`;

const StickyHeader = styled.div`
  box-sizing: border-box;
  width: 100%;

  &.floating {
    position: fixed;
    z-index: 10;
  }
`;

const ProgressBar = styled.div<{ progress: number }>`
  background: linear-gradient(
    to right,
    #ffc408 0 ${(props) => props.progress * 100}%,
    #fff 0
  );
  width: 100%;
  height: 13px;
`;

const ActionsContainer = styled.div`
  position: relative;
  padding: 10px 22px 16px;
  border-radius: 0 0 20px 20px;
  background: #ecf0f5;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const BigActionsContainer = styled.div`
  flex-grow: 1;

  .small-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    .end-icons {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
    }
  }

  & .big-actions {
    position: absolute;
    bottom: -24px;
    right: 22px;
    display: flex;
    gap: 10px;

    .play-icon,
    .video-icon {
      width: 60px !important;
      height: 60px !important;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
`;

const ActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 115%; /* 13.8px */
    color: #90a4ae;
  }
`;

const ActionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #90a4ae;
  border-radius: 50%;
  overflow: hidden;
`;

const ShareAnimation = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  animation: ${(props) => (props.active ? "launch 1.3s" : "none")};
  will-change: transform;

  @keyframes launch {
    0% {
      transform: translate(0px, 0px);
      scale: 1;
      rotate: 0deg;
      animation-timing-function: ease-in;
    }
    35% {
      transform: translate(-6px, 7px);
      scale: 0.8;
      rotate: 15deg;
      animation-timing-function: ease-in-out;
    }
    55% {
      transform: translate(40px, -60px);
      scale: 0.4;
      rotate: -40deg;
    }
    58% {
      transform: translate(50px, 30px);
    }
    60% {
      transform: translate(-50px, 50px);
      scale: 0.6;
      rotate: 30deg;
    }
    100% {
      transform: translate(0px, 0px);
      scale: 1;
      rotate: 0deg;
      animation-timing-function: ease-out;
    }
  }
`;

const JournalLink = styled(Link)`
  margin: 16px 0 24px;
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Inter;
  color: var(--SECONDARY-GREY-SHADES-Bluegrey-6, #4c5861);

  .from {
    font-size: 10px;
    font-weight: 300;
    line-height: 6px; /* 60% */
  }

  .journal-name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px; /* 50% */
  }
`;

const ContentWrapper = styled.div`
  padding: 0 32px 100px;
`;

const ContentPartWrapper = styled.div`
  section {
    padding: 40px 0 24px;
    border-bottom: 1px solid #d2dce2;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border: none;
    }
  }

  h2 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: Inter;
    font-size: 30px;
    font-weight: 900;
    line-height: 100%; /* 30px */
    color: var(--JUISCI-Black-Matters, #212121);

    svg {
      width: 30px;
      height: 30px;
    }
  }

  p,
  ul,
  .LinesEllipsis {
    margin: 24px 0;
    color: var(--JUISCI-Black-Matters, #212121);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.002px;
    word-break: break-word;
  }

  .LinesEllipsis:last-of-type {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 24px;

    li {
      ::before {
        content: "• ";
        color: #f42cab;
      }
    }
  }
`;

const AuthorList = styled.div`
  margin: 24px 0 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 6px;

  .author-item {
    display: flex;
    align-items: center;

    &::after {
      content: ",";
    }

    &:last-child::after {
      content: ""; /* Removes the comma for the last author */
    }

    svg {
      margin-right: 3px;
    }

    span {
      color: #212121;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const RelatedArticlesWrapper = styled.div`
  padding: 10px 8px calc(16px + var(--safe-area-bottom));
  background: linear-gradient(
    180deg,
    #ff699c 0%,
    #fe5763 26.99%,
    #ff8a00 51.99%,
    #fdb955 77.51%,
    #ffc408 100%
  );

  h2 {
    font-family: "Work Sans";
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
