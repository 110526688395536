import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../redux";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { FilterPanelActionBox, FilterPanelContent, FilterPanelFooter, FilterPanelHeader, LeftDrawer, SelectableItem } from "./layout";
import CustomIcon from "../CustomIcon";
import CustomSearchInput from "../CustomSearchInput";
import CustomRadio from "../CustomRadio";
import { SpecialtyModel } from "../../domains/app/app.types";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { UNAVAILABLE_SPECIALTIES } from "../../tools/utils";
import { SupportedLanguage } from "../../interfaces";
import Fuse from "fuse.js";
import AppButton from "../app/AppButton";
import { getStoredContentFilters, storeContentFilters } from "../../domains/app/utils/contentFilters";

export default function SpecialtyFilterPanel({
  isOpen,
  onClose,
  onSave,
}: {
  isOpen: boolean;
  onClose: Function;
  onSave: Function;
}) {
  const { activeLang, t } = useLangContext();
  const [searchValue, setSearchValue] = useState("");
  const storeSpecialties = useAppSelector((state) => state.specialties.medical_specialties);
  const availableSpecialties = storeSpecialties.filter((spe: SpecialtyModel) =>
    !UNAVAILABLE_SPECIALTIES.includes(getItemTranslation(spe, SupportedLanguage.EN))
  );
  const [selectedSpecialtyIds, setSelectedSpecialtyIds] = useState<string[]>([]);
  const [memSelectedSpecialtyIds, setMemSelectedSpecialtyIds] = useState<string[]>([]);
  const isAllSelected = selectedSpecialtyIds.length === availableSpecialties.length;
  const [visibleItems, setVisibleItems] = useState(availableSpecialties);
  const sortedItems = [...visibleItems]
    ?.sort((a: SpecialtyModel, b: SpecialtyModel) => {
      return getItemTranslation(a).localeCompare(getItemTranslation(b));
    });
  const scrollerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      if (getStoredContentFilters()) {
        setSelectedSpecialtyIds(getStoredContentFilters().specialtyIds ?? []);
      }
      setMemSelectedSpecialtyIds(selectedSpecialtyIds);
    } else {
      setTimeout(() => {
        setSearchValue("");
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchValue) {
      const fuse = new Fuse(availableSpecialties, {
        includeScore: true,
        threshold: 0.25,
        keys: [`translations.${activeLang}`],
      });
      const searchResults = fuse.search(searchValue).map((res: any) => res.item);
      setVisibleItems(searchResults);
    } else {
      setVisibleItems(availableSpecialties);
    }
  }, [storeSpecialties, searchValue]);

  function handleToggleSelect(spe: SpecialtyModel) {
    if (selectedSpecialtyIds.includes(spe._id)) {
      setSelectedSpecialtyIds(selectedSpecialtyIds.filter((id) => id !== spe._id));
    } else {
      setSelectedSpecialtyIds([...selectedSpecialtyIds, spe._id]);
      scrollerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  function handleSelectAll() {
    setSelectedSpecialtyIds(availableSpecialties.map((spe: SpecialtyModel) => spe._id));
  }

  function handleUnselectAll() {
    setSelectedSpecialtyIds([]);
  }

  function handleCloseWithoutSaving() {
    onClose();
    setTimeout(() => {
      setSelectedSpecialtyIds(memSelectedSpecialtyIds);
    }, 100);
  }

  function handleSubmit() {
    const specialties = selectedSpecialtyIds.map((id) => {
      return availableSpecialties.find((spe: SpecialtyModel) => spe._id === id);
    });
    storeContentFilters({
      specialtyIds: selectedSpecialtyIds,
      specialtyUids: specialties.map(spe => spe.uid),
    });
    onSave();
    onClose();
  }

  return (
    <LeftDrawer isOpen={isOpen}>

      <FilterPanelHeader>
        <CustomIcon
          className="close-button"
          iconName="close_alt"
          color="#313B42"
          onClick={handleCloseWithoutSaving}
        />
      </FilterPanelHeader>

      <FilterPanelContent ref={scrollerRef}>

        <span className="info">{t("content:filterBy.specialties")}</span>

        <CustomSearchInput
          placeholder={t("common:action.search")}
          onSearch={(value: string) => setSearchValue(value)}
          onClear={() => {
            setSearchValue("");
          }}
        />

        {searchValue.length === 0 && (
          <div>
            <span className="heading">{t("common:default")}</span>

            <FilterPanelActionBox>
              <CustomRadio
                onChange={handleSelectAll}
                checked={isAllSelected}
                title={t("common:action.selectAll")}
                textStyle={{
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 700,
                  width: "max-content",
                }}
              />

              <div
                className={`clear-all ${selectedSpecialtyIds.length > 0 ? "active" : ""}`}
                onClick={handleUnselectAll}
              >
                <CustomIcon iconName="times-radio" />
                {t("common:action.clearAll")}
              </div>
            </FilterPanelActionBox>
          </div>
        )}

        <hr style={{ margin: "16px 0" }} />

        <span className="heading">{t("common:specialties")}</span>

        {[
          ...sortedItems.filter((spe: SpecialtyModel) => selectedSpecialtyIds.includes(spe._id)),
          ...sortedItems.filter((spe: SpecialtyModel) => !selectedSpecialtyIds.includes(spe._id)),
        ].map((spe: SpecialtyModel) => {
          const isSelected = selectedSpecialtyIds.includes(spe._id);
          return (
            <SelectableItem key={spe._id} className={isSelected ? "selected" : ""}>
              <span>{getItemTranslation(spe)}</span>
              <button onClick={() => handleToggleSelect(spe)}>
                {isSelected ? t("common:action.unselect") : t("common:action.select")}
              </button>
            </SelectableItem>
          );
        })}

      </FilterPanelContent>

      <FilterPanelFooter>
        <AppButton className="xl" onClick={handleSubmit}>
          {t("common:panel.options.label.save")}
        </AppButton>
      </FilterPanelFooter>
    </LeftDrawer >
  );
}
