import { useState, useEffect } from "react";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import styled from "styled-components";
import SaveHeader from "../../components/app/headers/SaveHeader";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { unfollowJournal } from "../../domains/journal/endpoints/unfollowJournal";
import { displayToast } from "../../components/app/AppToast";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import { JournalModel } from "../../domains/journal/journal.types";
import { useHistory } from "react-router-dom";
import AppButton from "../../components/app/AppButton";
import { useAppDispatch } from "../../redux";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";

export default function ProfileJournalPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useLangContext();
  const location = useLocation<{ journals?: JournalModel[] }>();
  const [journals, setJournals] = useState<string[]>([]);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    window.scroll(0, 0);
    initialize();
  }, [history]);

  async function initialize() {
    try {
      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.default"),
      });
      if (isRedirected) return;
    } catch (error) {
      console.error("Error during initialization", error);
    }
  }

  function handleFollow({ _id }: { _id: string }) {
    setJournals((prev) =>
      prev.includes(_id) ? prev.filter((el) => el !== _id) : [...prev, _id]
    );
  }

  async function handleSubmit() {
    try {
      for (const journalId of journals) {
        await unfollowJournal(journalId);
      }
      await getCurrentUser();
      displayToast(t("toast:success.profileUpdated"));
      history.goBack();
    } catch (error) {
      console.error("Couldn't edit journals.", error);
      displayToast(t("error:default"));
    }
  }

  if (!location.state?.journals) return <Loader />;

  return (
    <Container>
      <SaveHeader
        title={t("profile:journal.title")}
        saveReady={true}
        onSaveClick={handleSubmit}
      />
      <PageContent>
        {location.state.journals?.map((journal) => (
          <JournalCard
            onClick={() => history.push("/journal/" + journal.uid)}
            onUnfollow={() => handleFollow(journal)}
            active={!journals.includes(journal._id)}
            journal={journal}
            key={journal?._id}
          />
        ))}
      </PageContent>
    </Container>
  );
}

function JournalCard({
  journal,
  active,
  onUnfollow,
  onClick,
}: {
  journal: JournalModel;
  active: boolean;
  onUnfollow: () => void;
  onClick: () => void;
}) {
  const { t } = useLangContext();
  return (
    journal && (
      <div className={`--journal-profile-card`}>
        <div className="title-img" onClick={onClick}>
          <img src={journal?.image?.url} />
          <div className="title">{journal?.name}</div>
        </div>
        <div className="btn">
          <AppButton
            onClick={onUnfollow}
            className={`s ${active ? "active" : ""}`} // TODO: Add active state to AppButton
          >
            {t(!active ? "common:action.follow" : "common:action.unfollow")}
          </AppButton>
        </div>
      </div>
    )
  );
}

const Container = styled.div`
  min-height: 100dvh;
  background: #f7f8fc;
`;

const PageContent = styled.div`
  padding: 40px 20px 50px 20px;

  .--journal-profile-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 24px; */
    padding: 24px 16px;
    margin: 8px 0px;
    overflow: hidden;

    background: linear-gradient(
        180deg,
        rgba(210, 220, 226, 0) 52.08%,
        rgba(210, 220, 226, 0.5) 100%
      ),
      #ffffff;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    border-radius: 15px;

    .btn {
      width: max-content;
    }

    .title-img {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        max-width: 64px;
        max-height: 64px;
        object-fit: contain;
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 120%;
        color: #212121;
        width: 100px;
      }
    }
  }
`;
