import { getApiRequestService } from "../../../services/index.services";

export async function readArticle(articleId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(`/article/read/${articleId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
