import { atom, useAtom } from "jotai";
import { ArticleModel } from "../../article/article.types";
import { NectarModel } from "../../nectar/nectar.types";
import { VideoModel } from "../../video/video.types";
import { DebugLogger } from "../../app/utils/debugLogger";

const debug = new DebugLogger("For You Cache");
debug.mute();

enum Cache {
  RECOMMENDED_PUBLIC = "recommended_public",
  RECOMMENDED_ROOM = "recommended_room",
}

interface CacheListData {
  list: (ArticleModel | NectarModel | VideoModel)[];
  total: number;
}

interface CacheLists {
  [Cache.RECOMMENDED_PUBLIC]: CacheListData;
  [Cache.RECOMMENDED_ROOM]: CacheListData;
}

const defaultCacheLists: CacheLists = {
  [Cache.RECOMMENDED_PUBLIC]: { list: [], total: 0 },
  [Cache.RECOMMENDED_ROOM]: { list: [], total: 0 },
};

const cacheListsAtom = atom<CacheLists>(defaultCacheLists);

function useForYouCache(cacheId: Cache) {
  const [cacheLists, setCacheLists] = useAtom(cacheListsAtom);

  function saveCache(listType: Cache, docs: (ArticleModel | NectarModel | VideoModel)[], total: number) {
    debug.log("Save cache", { listType, docs, total });
    setCacheLists((lists) => ({ ...lists, [listType]: { list: docs, total } }));
  }

  return {
    list: cacheLists[cacheId].list,
    total: cacheLists[cacheId].total,
    update: (content: (ArticleModel | NectarModel | VideoModel)[], total: number) => {
      saveCache(cacheId, content, total);
    },
    // Utility functions accessing all caches
    clearAllCaches: () => setCacheLists(defaultCacheLists),
  };
}

// Public recommended content
export function useForYouCacheRecommendedPublic() {
  return useForYouCache(Cache.RECOMMENDED_PUBLIC);
}

// Room recommended content
export function useForYouCacheRecommendedRoom() {
  return useForYouCache(Cache.RECOMMENDED_ROOM);
}
