import { ReactNode } from "react";
import styled from "styled-components";
import { ReactComponent as JuisciIcon } from "../assets/core/pipe-icon.svg";
import EditoIcon from "../assets/images/edito-icon.png";
import { ReactComponent as YellowArrowIcon } from "../assets/icons/arrow-left-circle-yellow.svg";

export default function ContentExternalLink({
  href,
  title,
  text,
  style = {},
  isEditorial = false,
  onClick = () => null,
}: {
  href: string;
  title: string | ReactNode;
  text?: string | ReactNode;
  style?: {};
  isEditorial?: boolean;
  onClick?: Function;
}) {
  return (
    <a href={href} target='_blank' onClick={(e) => {
      e.stopPropagation();
      onClick && onClick();
    }}>
      <Container style={style}>
        {!isEditorial
          ? <JuisciIcon style={{ position: "absolute", top: -25, height: 45 }} />
          : <img src={EditoIcon} alt="Edito" style={{ position: "absolute", top: -25, left: 15, height: 45 }} />
        }
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <Heading>{title}</Heading>
            <Text>{text}</Text>
          </div>
          <StyledArrow style={{ height: 50 }} />
        </div>
      </Container>
    </a>
  );
};

const Container = styled.div`
  position: relative;
  padding: 30px 24px;
  border-radius: 20px;
  background: #ECF0F5;
`;

const Heading = styled.div`
  color: #4C5861;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%; /* 16px */
`;

const Text = styled.div`
  margin-top: 8px;
  color: #4C5861;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
`;

const StyledArrow = styled(YellowArrowIcon)`
  width: 45px;
  height: 45px;
  transform: rotate(180deg);
`;