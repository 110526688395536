import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { RoomModel } from "../../domains/room/room.types";
import { getCongressList } from "../../domains/room/endpoints/getCongressList";
import { getContentCongress } from "../../domains/room/endpoints/getCongressContent";
import { useRoomPreflight } from "../../domains/room/hooks/useRoomPreflight";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonContentCard from "../../components/profile/SkeletonContentCard";
import SafeAreaTopWrapper from "../../components/app/SafeAreaTopWrapper";
import { HeaderBoxSimple, HeaderLinks, NavHome, NavLang } from "../../components/app/headers/layout";
import { RoomHeader, RoomListHeading, RoomListWrapper, RoomPageWrapper } from "../../components/room/layout";
import CustomSelect from "../../components/customSelect";
import VideoCard from "../../components/cards/VideoCard";
import { ReactComponent as FeedIcon } from "../../assets/icons/feed-white.svg";
import { ContentFormat } from "../../interfaces/content.interface";
import InfographicCard from "../../components/cards/InfographicCard";
import RoomBottomNavbar from "../../components/app/RoomBottomNavbar";

export default function RoomConferencesPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { activeLang, t } = useLangContext();
  // NOTE: Must be here to support translation.
  const CONGRESS_OPTION_ALL = {
    _id: "all",
    name: t("room:conferences.allConferences"),
  };
  const { isInRoom, currentRoom } = useRoomContext();
  const [congressOptions, setCongressOptions] = useState<any[]>([CONGRESS_OPTION_ALL]); // TODO: type.
  const [selectedCongressId, setSelectedCongressId] = useState(CONGRESS_OPTION_ALL._id)
  const [content, setContent] = useState<any[]>([]); // TODO: type.
  const [total, setTotal] = useState(0);
  const [isFetchingContent, setFetchingContent] = useState(true);
  const { isLoadingRoom, preflightRoom } = useRoomPreflight(roomId);

  // TODO: cache content

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        const { isRedirected } = await preflightRoom();
        if (isRedirected) return;

        await loadCongressOptions();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (isInRoom) {
      loadContent(true);
    }
  }, [isInRoom, activeLang, selectedCongressId]);

  async function loadCongressOptions() {
    try {
      const congressList = await getCongressList(activeLang);

      setCongressOptions([
        CONGRESS_OPTION_ALL,
        ...congressList,
      ]);
    } catch (error) {
      console.error("Couldn't load congress options.", error);
    }
  }

  async function loadContent(resetList = false) {
    try {
      if (resetList) scrollTo(0, 0);

      setFetchingContent(true);
      const queryParams: any = {
        limit: 20,
        offset: resetList ? 0 : content.length,
        language: activeLang,
        // medical_specialties: string, // FILTERS?
      };

      if (selectedCongressId !== CONGRESS_OPTION_ALL._id) {
        queryParams.congress = selectedCongressId;
      }

      const { docs, total } = await getContentCongress(queryParams);
      const newList = resetList ? docs : [...content, ...docs];
      setContent(newList);
      setTotal(total);
      setFetchingContent(false);
    } catch (error) {
      console.error("Couldn't load content.", error);
    }
  }

  function handleSelectCongress(e: ChangeEvent<HTMLSelectElement>) {
    setSelectedCongressId(e.target.value);
  }

  if (isLoadingRoom) return <Loader />;

  return (
    <RoomPageWrapper style={{ backgroundColor: currentRoom?.primaryColor ?? "" }}>
      <SafeAreaTopWrapper>
        <HeaderBoxSimple>
          <HeaderLinks><NavHome /></HeaderLinks>
          <RoomHeader room={currentRoom as RoomModel} />
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxSimple>
      </SafeAreaTopWrapper>

      {/* TODO: Congress selector. */}
      <div style={{ padding: 16 }}>
        <CustomSelect
          label={t("room:conferences.conferences")}
          value={selectedCongressId}
          noEmptyOption
          renderOptions={congressOptions.map((option: { _id: string, name: string }) => (
            <option key={option._id} value={option._id}>{option.name}</option>
          ))}
          onChange={handleSelectCongress}
        />
      </div>

      <div style={{ flex: 1 }}>
        <RoomListHeading><FeedIcon />{t("room:conferences:feed")}</RoomListHeading>

        {isFetchingContent ? (
          <FadeIn key="loading">
            <RoomListWrapper>
              {[...Array(10)].map((_, index) => <SkeletonContentCard key={index} />)}
            </RoomListWrapper>
          </FadeIn>
        ) : (
          <FadeIn key="content">
            <InfiniteScroll
              dataLength={content.length}
              next={() => loadContent()}
              hasMore={content.length < total}
              pullDownToRefresh={false}
              loader={null}
            >
              <RoomListWrapper>
                {!content.length && (
                  <span className="no-results">{t("room:conferences.noResults")}</span>
                )}
                {content.map((content) => {
                  if (content.content_format === ContentFormat.VIDEO) {
                    return (
                      <VideoCard
                        key={content._id}
                        video={content}
                        // TODO: Change.
                        analyticsListName={AnalyticsItemList.DISCOVERY_MAIN_CAROUSEL}
                      />
                    );
                  }
                  if (content.content_format === ContentFormat.INFOGRAPHIC) {
                    return (
                      <InfographicCard
                        key={content._id}
                        infographic={content}
                        // TODO: Change.
                        analyticsListName={AnalyticsItemList.DISCOVERY_MAIN_CAROUSEL}
                      />
                    );
                  }
                  return null;
                })}
              </RoomListWrapper>
            </InfiniteScroll>
          </FadeIn>
        )}

      </div>

      <RoomBottomNavbar />
    </RoomPageWrapper>
  );
}
