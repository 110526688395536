import { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import styled from "styled-components";
import Loader from "../../components/Loader";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderLogo,
  NavBack,
} from "../../components/app/headers/layout";
import { maxVideoViewed } from "../../tools/utils";
import { renderDate } from "../../domains/app/utils/renderDate";
import disableScroll from "disable-scroll";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import ArticleCard from "../../components/cards/ArticleCard";
import { viewContent } from "../../domains/content/endpoints/viewContent";
import { getContentRelatedArticles } from "../../domains/content/endpoints/getContentRelatedArticles";
import { displayToast } from "../../components/app/AppToast";
import { getContentFromSlug } from "../../domains/content/endpoints/getContentFromSlug";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListArticle } from "../../tools/analytics/articleAnalytics";
import {
  gaEventVideoLike,
  gaEventVideoPlay,
  gaEventVideoShare,
  gaEventViewItemVideo,
} from "../../tools/analytics/videoAnalytics";
import ProfilePicture from "../../components/ProfilePicture";
import SpecialtyBadges from "../../components/app/SpecialtyBadges";
import ApiVideoPlayer from "@api.video/react-player";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { VideoModel } from "../../domains/video/video.types";
import { ArticleModel } from "../../domains/article/article.types";
import { useLikeVideo } from "../../domains/user/hooks/useLikeVideo";
import { useSaveVideo } from "../../domains/user/hooks/useSaveVideo";
import { useShareVideo } from "../../domains/user/hooks/useShareVideo";
import PlaylistPanel from "../../components/app/PlaylistPanel";
import { ReactComponent as BookmarkIcon } from "../../assets/icons/bookmark-fill.svg";
import { ReactComponent as LikeIcon } from "../../assets/icons/like-fill.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/send-fill.svg";
import { ReactComponent as PublicationIcon } from "../../assets/icons/publication.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-circle-right-duotone.svg";

const YOUTUBE_CONFIG = {
  playerVars: {
    showinfo: 0,
    modestbranding: 1,
    iv_load_policy: 3,
    rel: 0,
    wmode: "transparent",
    playsinline: 1,
    controls: 1,
    fs: 0,
    disablekb: 1,
  },
};

export default function VideoArticlePage() {
  const { currentUser } = useCurrentUser();
  const { t } = useLangContext();
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();
  const history = useHistory();
  const [video, setVideo] = useState<VideoModel | null>(null);
  const [playing, setPlaying] = useState(false);
  const [relatedArticles, setRelatedArticles] = useState<ArticleModel[]>();
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const [isShareHighlighted, setIsShareHighlighted] = useState(false);
  const [wasShared, setWasShared] = useState(false);
  const [isWaitingActionResult, setWaitingActionResult] = useState(false);
  const [memLiked, setMemLiked] = useState<boolean | null>(null);
  const [memSaved, setMemSaved] = useState<boolean | null>(null);

  const { isLiked, toggleLike } = useLikeVideo(
    video,
    AnalyticsItemList.VIDEO_ARTICLE_PAGE
  );
  const { isSaved, toggleSaveToPlaylists } = useSaveVideo(
    video,
    AnalyticsItemList.VIDEO_ARTICLE_PAGE
  );
  const shareVideo = useShareVideo(video, AnalyticsItemList.VIDEO_ARTICLE_PAGE);

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoHeight = videoContainerRef.current?.offsetHeight || 0;
  const safeAreaInsetTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top')) || 0;
  const topMargin = videoHeight + safeAreaInsetTop;

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (video) {
      (async function () {
        try {
          await viewContent(video._id);
          gaEventViewItemVideo({
            video,
            listName: AnalyticsItemList.VIDEO_ARTICLE_PAGE,
          });
        } catch (error) {
          console.error("Couldn't view video");
        }
      })();
    }
  }, [video]);

  async function initialize() {
    try {
      disableScroll.off();
      window.scrollTo(0, 0);

      const isAuthentified = await isUserAuthentified();
      if (!isAuthentified && maxVideoViewed("article")) {
        localStorage.setItem("authRedirectPath", location.pathname);
        displayToast(t("error:notLoggedIn"));
        return history.replace("/login");
      }

      const videoData = await getContentFromSlug(slug);
      // console.log("Video data:", videoData);
      setVideo(videoData);

      // if (memLiked === null) {
      //   setMemLiked(currentUser?.likedContent?.some((el: { _id: string }) => el._id === videoData._id) ?? false);
      // }
      // if (memSaved === null) {
      //   setMemSaved(currentUser?.saves?.some((videoId: string) => videoId === videoData._id) ?? false);
      // }

      setTimeout(() => {
        setPlaying(true);
        gaEventVideoPlay({
          video: videoData,
          listName: AnalyticsItemList.VIDEO_ARTICLE_PAGE,
        });
      }, 1000);

      fetchRelatedArticles(videoData._id);
    } catch (error) {
      console.error("Couldn't mount.", error);
      displayToast(t("error:default"));
    }
  }

  async function fetchRelatedArticles(videoId: string) {
    try {
      const articles = await getContentRelatedArticles(videoId);
      // console.log("Related articles:", articles);
      setRelatedArticles(articles);

      gaEventViewItemListArticle({
        articles,
        listName: AnalyticsItemList.VIDEO_ARTICLE_PAGE,
      });
    } catch (error) {
      console.error("Couldn't fetch related articles.", error);
      displayToast(t("error:default"));
    }
  }

  const getVideoPublisherAvatar = (video: VideoModel) => {
    switch (video.publisher) {
      case "journal":
        return {
          image: video.journal?.image?.url || "",
          path: video.journal ? `/journal/${video.journal.uid}` : "",
        };
      case "user":
        return {
          path: `/profile/user/${video.user?.uid}`,
          image: null,
        };
      case "congress":
        return {
          image: video.congress?.image?.url || "",
          path: null,
        };
      default:
        return {
          image: video.company?.images[0]?.url || "",
          path: `/company/${video.company?._id}`,
        };
    }
  };

  const videoPublisherAvatar = video ? getVideoPublisherAvatar(video) : null;

  async function handleSave({
    add,
    remove,
  }: {
    add: string[];
    remove: string[];
  }) {
    if (memSaved === null) setMemSaved(isSaved);
    await toggleSaveToPlaylists({ add, remove });

    if (!isSaved) {
      setTimeout(() => {
        setIsShareHighlighted(true);
        setTimeout(() => setIsShareHighlighted(false), 1500);
      }, 1000);
    }
  }

  async function handleLike() {
    if (isWaitingActionResult) return;
    if (memLiked === null) setMemLiked(isLiked);

    setWaitingActionResult(true);
    await toggleLike();
    setWaitingActionResult(false);

    if (!isLiked) {
      setIsShareHighlighted(true);
      setTimeout(() => setIsShareHighlighted(false), 1500);
    }
  }

  async function handleShare() {
    shareVideo();
    setWasShared(true);
  }

  function getMetricBonus(currentValue: boolean, memValue: boolean | null) {
    if (memValue !== null && currentValue !== memValue) {
      return !!currentValue ? 1 : -1;
    }
    return 0;
  }

  return (
    <PageContainer>
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack
            onClick={() => {
              !!location.key
                ? history.goBack()
                : history.replace("/discovery/videos?wasShared=true");
            }}
          />
        </HeaderLinks>
        <HeaderLogo />
        <HeaderLinks />
      </HeaderBoxAutoSafe>

      <VideoPlayerContainer ref={videoContainerRef}>
        {!video ? (
          <Loader />
        ) : (
          <>
            {video?.apiVideo?.videoId ? (
              <ApiVideoPlayer
                video={{ id: video.apiVideo.videoId }}
                style={{
                  width: "100%",
                  height: "300px",
                }}
                videoStyleObjectFit={window.innerWidth > 550 ? "fill" : "cover"}
                autoplay
                controls={[
                  "play",
                  "seekBackward",
                  "seekForward",
                  "playbackRate",
                  "volume",
                  "progressBar",
                  // "fullscreen",
                ]}
              />
            ) : (
              // NOTE: ???
              // @ts-ignore
              <ReactPlayer
                width="100%"
                height="210px"
                url={video?.sourceURL}
                config={{
                  youtube: { ...YOUTUBE_CONFIG },
                }}
                playing={playing}
                onReady={() => setPlaying(true)}
                onStart={() => setPlaying(true)}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                stopOnUnmount={true}
                playsinline={true}
                controls={true}
              />
            )}
          </>
        )}
      </VideoPlayerContainer>

      <InformationContainer style={{ marginTop: topMargin }}>
        <div className="header">
          <div className="date-specialty">
            <div className="date">
              {video?.publication_date && renderDate(video.publication_date)}
            </div>
            <div className="specialty">
              <SpecialtyBadges specialties={video?.medical_specialties || []} />
            </div>
          </div>
          {videoPublisherAvatar?.path ? (
            <Link to={videoPublisherAvatar.path}>
              <div
                className={`publisher-image ${
                  video?.publisher === "user" ? "avatar" : ""
                }`}
              >
                {video?.publisher === "user" ? (
                  <ProfilePicture height={56} user={video?.user} />
                ) : (
                  <img src={videoPublisherAvatar.image || undefined} alt="" />
                )}
              </div>
            </Link>
          ) : (
            <div className="publisher-image">
              <img src={videoPublisherAvatar?.image || undefined} alt="" />
            </div>
          )}
        </div>

        <h2 className="title">{video?.title}</h2>

        <ActionsContainer>
          <ActionItem>
            <ActionItemWrapper
              onClick={() => setPlaylistsPanelVisible(true)}
              style={{ background: isSaved ? "#FFC408" : "#90A4AE" }}
            >
              <BookmarkIcon style={{ color: "#FFFFFF" }} />
            </ActionItemWrapper>
            <span>
              {Math.max(
                0,
                (video?.metrics?.saveds ?? 0) +
                  getMetricBonus(isSaved, memSaved)
              )}
            </span>
          </ActionItem>

          <ActionItem>
            <ActionItemWrapper
              onClick={handleLike}
              style={{ background: isLiked ? "#FF8800" : "#90A4AE" }}
            >
              <LikeIcon style={{ color: "#FFFFFF" }} />
            </ActionItemWrapper>
            <span>
              {Math.max(
                0,
                (video?.metrics?.likes ?? 0) + getMetricBonus(isLiked, memLiked)
              )}
            </span>
          </ActionItem>

          <ActionItem>
            <ActionItemWrapper onClick={handleShare}>
              <ShareAnimation active={isShareHighlighted}>
                <ShareIcon />
              </ShareAnimation>
            </ActionItemWrapper>
            <span>
              {Math.max(0, (video?.metrics?.shares ?? 0) + (wasShared ? 1 : 0))}
            </span>
          </ActionItem>
        </ActionsContainer>
      </InformationContainer>

      {(!!video?.associatedArticles?.[0] || video?.externalLink) && (
        <AssociatedContainer>
          {!!video?.associatedArticles?.[0] && (
            <Link
              to={`/post/${video.associatedArticles[0].slug}`}
              className="content-link associated"
            >
              <div className="icon-wrapper associated">
                <PublicationIcon width={20} height={20} />
              </div>
              <div className="text-content">
                <h5>{t("content:videoArticlePage.associatedArticle")}</h5>
                <p>{t("content:videoArticlePage.associatedArticleText")}</p>
              </div>
              <ArrowIcon width={45} height={45} />
            </Link>
          )}

          {video?.externalLink && (
            <a
              href={video.externalLink}
              className="content-link external"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon-wrapper external">
                <LinkIcon color="#ffffff" width={20} height={20} />
              </div>
              <div className="text-content">
                <h5>{t("content:videoArticlePage.externalLink.title")}</h5>
              </div>
              <ArrowIcon width={45} height={45} />
            </a>
          )}
        </AssociatedContainer>
      )}

      <RelatedContentContainer>
        <div className="section-title">
          <PublicationIcon width={25} height={25} />
          <h3>{t("content:videoArticlePage.recommendedPublications")}</h3>
        </div>
        {!!relatedArticles ? (
          <CardList>
            {relatedArticles.map((article) => (
              <ArticleCard
                key={`${article._id}--relatedArticles`}
                article={article}
                analyticsListName={AnalyticsItemList.ARTICLE_PAGE}
              />
            ))}
          </CardList>
        ) : (
          <Loader loaderOnly />
        )}
      </RelatedContentContainer>

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={video}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={handleSave}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  position: relative;
`;

const VideoPlayerContainer = styled.div`
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  z-index: 9;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
  background: #ecf0f5;
  gap: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .date-specialty {
      .date {
        font-size: 12px;
        color: #212121;
        margin-bottom: 8px;
      }

      .specialty {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }
    }

    .publisher-image {
      img {
        max-width: 80px;
        max-height: 40px;
        object-fit: contain;
      }

      &.avatar img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.02em;
    color: #212121;
    margin: 0;
  }

  .actions {
    display: flex;
    gap: 16px;
    align-items: center;

    .action-button {
      display: flex;
      align-items: center;
      gap: 5px;

      .count {
        font-size: 14px;
        color: #90a4ae;
        margin: 0;
      }
    }
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 115%;
    color: #90a4ae;
  }
`;

const ActionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #90a4ae;
  border-radius: 50%;
  overflow: hidden;
`;

const ShareAnimation = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  animation: ${(props) => (props.active ? "launch 1.3s" : "none")};
  will-change: transform;

  @keyframes launch {
    0% {
      transform: translate(0px, 0px);
      scale: 1;
      rotate: 0deg;
      animation-timing-function: ease-in;
    }
    35% {
      transform: translate(-6px, 7px);
      scale: 0.8;
      rotate: 15deg;
      animation-timing-function: ease-in-out;
    }
    55% {
      transform: translate(40px, -60px);
      scale: 0.4;
      rotate: -40deg;
    }
    58% {
      transform: translate(50px, 30px);
    }
    60% {
      transform: translate(-50px, 50px);
      scale: 0.6;
      rotate: 30deg;
    }
    100% {
      transform: translate(0px, 0px);
      scale: 1;
      rotate: 0deg;
      animation-timing-function: ease-out;
    }
  }
`;

const AssociatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  padding: 32px 22px;

  .content-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    text-decoration: none;
    gap: 20px;

    &.associated {
      background: #fff3ce;
    }

    &.external {
      background: #ecf0f5;
    }

    .icon-wrapper {
      position: absolute;
      top: -20px;
      left: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      &.associated {
        background: #ffc409;
      }

      &.external {
        background: #ce0868;
      }
    }

    .text-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h5 {
        font-family: "Inter";
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #313b42;
        margin: 0;
      }

      p {
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
        color: #313b42;
        margin: 0;
      }
    }
  }
`;

const RelatedContentContainer = styled.div`
  padding: 32px 0;
  min-height: 100vh;
  background: linear-gradient(180deg, #ff8a00 0%, #fdb955 47.92%, #ffc408 100%);
  display: flex;
  flex-direction: column;
  gap: 32px;

  .section-title {
    padding: 0px 22px;
    display: flex;
    align-items: center;
    gap: 16px;

    h3 {
      font-family: "Inter";
      font-weight: 700;
      font-size: 20px;
      line-height: 110%;
      color: #212121;
      margin: 0;
    }
  }
`;

const CardList = styled.div`
  padding: 0 8px calc(16px + var(--safe-area-bottom));
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
