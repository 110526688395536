import { useEffect, useState } from "react";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavHome,
  NavLang,
} from "../../components/app/headers/layout";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import RENAME_VideoCard from "../../components/VideoCard";
import VideoCard2 from "../../components/cards/VideoCard";
import LoginPanel from "../../components/signup/LoginPanel";
import SpecialtyFilterPanel from "../../components/filters/SpecialtyFilterPanel";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListVideo } from "../../tools/analytics/videoAnalytics";
import { useVideoStoriesListWithCache } from "../../domains/video/endpoints/getVideoStoriesList";
import { useVideoArticlesListWithCache } from "../../domains/video/endpoints/getVideoArticlesList";
import ContentFilterButton from "../../components/filters/ContentFilterButton";
import ItemList from "../../components/app/ItemList";
import VideoCardSkeleton from "../../components/skeleton/VideoCardSkeleton";
import { getStoredContentFilters } from "../../domains/app/utils/contentFilters";
import { VideoModel } from "../../domains/video/video.types";
import { displayToast } from "../../components/app/AppToast";
import { useVideoCacheArticles, useVideoCacheStories } from "../../domains/video/hooks/useVideoCache";
import { useReadyState } from "../../domains/app/hooks/useReadyState";
import SkeletonCard from "../../components/skeleton/SkeletonCard";
import { usePageScroll } from "../../domains/app/hooks/usePageScroll";

export default function AllVideosPage() {
  const history = useHistory();
  const location = useLocation<{ fromHome?: boolean }>();
  const { activeLang, t } = useLangContext();
  const { setAsReady, useMount, useReadyEffect } = useReadyState();
  const { list: videoStories } = useVideoCacheStories();
  const { list: videoArticles } = useVideoCacheArticles();
  const getVideoStoriesList = useVideoStoriesListWithCache();
  const getVideoArticlesList = useVideoArticlesListWithCache();
  const displayedVideoStories = videoStories.slice(0, 10);
  const [isLoadingVideoStories, setLoadingVideoStories] = useState(videoStories.length === 0);
  const [isLoadingVideoArticles, setLoadingVideoArticles] = useState(videoArticles.length === 0);
  const [specialtyFilters, setSpecialtyFilters] = useState<string[]>(
    getStoredContentFilters().specialtyIds ?? []
  );
  const [isLoginPanelVisible, setLoginPanelVisible] = useState(false);
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false);
  const { savePageScrollY, restorePageScrollY } = usePageScroll();

  useMount(() => {
    initialize();
  });

  useReadyEffect(() => {
    loadVideoStories();
    loadVideoArticles();
  }, [activeLang]);

  useEffect(() => {
    if (videoStories.length > 0) {
      gaEventViewItemListVideo({
        videos: videoStories,
        listName: AnalyticsItemList.DISCOVERY_VIDEOS,
      });
    }
  }, [videoStories]);

  useEffect(() => {
    if (videoArticles.length > 0) {
      gaEventViewItemListVideo({
        videos: videoArticles,
        listName: AnalyticsItemList.DISCOVERY_VIDEOS,
      });
    }
  }, [videoArticles]);

  async function initialize() {
    try {
      if (videoArticles.length > 0 && !location.state.fromHome) {
        restorePageScrollY();
      }

      const isAuthentified = await isUserAuthentified();

      if (videoStories.length < 10) loadVideoStories();
      if (videoArticles.length === 0) loadVideoArticles();

      const searchParams = new URLSearchParams(history.location.search);
      const wasShared = searchParams.get("wasShared");
      if (wasShared && !isAuthentified) {
        setLoginPanelVisible(true);
      }
      setAsReady();
    } catch (error) {
      console.error("Couldn't mount page.", error);
      displayToast(t("error:default"));
    }
  }

  async function loadVideoStories() {
    try {
      setLoadingVideoStories(true);

      // NOTE: No specialty filter for video stories.
      const params = {
        offset: 0,
        limit: 20,
        language: activeLang,
      };

      await getVideoStoriesList(params);
      setLoadingVideoStories(false);
    } catch (error) {
      console.error("Couldn't load video stories.", error);
    }
  }

  async function loadVideoArticles(filters: string[] = specialtyFilters) {
    try {
      setLoadingVideoArticles(true);

      const params: any = {
        offset: 0,
        limit: 50,
        language: activeLang,
      };

      if (filters.length > 0) {
        params.medicalSpecialties = filters.join(",");
      }

      await getVideoArticlesList(params);
      setLoadingVideoArticles(false);
    } catch (error) {
      console.error("Couldn't load video articles.", error);
    }
  }

  function handleChangeFilters() {
    const filters = getStoredContentFilters().specialtyIds ?? specialtyFilters;
    setSpecialtyFilters(filters);
    loadVideoArticles(filters);
  }

  return (
    <>
      <PageContainer>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavHome /></HeaderLinks>
          <HeaderTitle>{t("content:type.videos")}</HeaderTitle>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <div className="heading">
          <h5>{t("home:videos.title")}</h5>
          <Link to="/videos/stories">{t("common:action.seeAll")}</Link>
        </div>

        <HScrollContainer className="scrollbar-hidden">
          {isLoadingVideoStories ? (
            <VideoCardSkeleton />
          ) : (
            displayedVideoStories.map((story: VideoModel) => (
              <RENAME_VideoCard
                variant="story"
                key={`${story._id}--story-homevideocard`}
                video={story}
                onClick={() => {
                  history.push({
                    pathname: `/video/story/${story.slug}`,
                    state: { story, stories: videoStories },
                  });
                }}
              />
            ))
          )}
        </HScrollContainer>

        <hr style={{ margin: "30px 0" }} />

        <div className="heading">
          <h5>{t("home:videos.title2")}</h5>
          <ContentFilterButton
            active={!!specialtyFilters?.length}
            onClick={() => setFilterPanelVisible(true)}
          />
        </div>

        <ItemList>
          {isLoadingVideoArticles ? (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          ) : videoArticles.length === 0 ? (
            <p style={{ margin: "0 16px" }}>{t("common:noContent")}</p>
          ) : videoArticles?.map((video: VideoModel) => (
            <VideoCard2
              key={video._id + "--article-homevideocard"}
              video={video}
              analyticsListName={AnalyticsItemList.DISCOVERY_VIDEOS}
              onClick={() => savePageScrollY()}
            />
          ))}
        </ItemList>

      </PageContainer>

      <LoginPanel
        isOpen={isLoginPanelVisible}
        onClose={() => setLoginPanelVisible(false)}
      />

      <SpecialtyFilterPanel
        isOpen={isFilterPanelVisible}
        onClose={() => setFilterPanelVisible(false)}
        onSave={handleChangeFilters}
      />
    </>
  );
}

const PageContainer = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: #f7f8fc;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;

    span {
      color: #ce0868;
    }
  }

  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 21px;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  padding: 0 24px 24px;
  display: flex;
  align-items: stretch;
  gap: 8px;
`;
