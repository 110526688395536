import { useEffect, useState } from "react";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { useReadyState } from "../../domains/app/hooks/useReadyState";
import { useNectarsListWithCache } from "../../domains/nectar/endpoints/getNectarsList";
import { useNectarCacheScroller, useNectarCacheSwipeList } from "../../domains/nectar/hooks/useNectarCache";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventSelectItemNectar } from "../../tools/analytics/nectarAnalytics";
import NectarScroller from "../app/NectarScroller";

export default function PageNectarScroller({
  isPublic,
}: {
  isPublic: boolean;
}) {
  const { activeLang } = useLangContext();
  const { isInRoom, currentRoomId } = useRoomContext();
  const { setAsReady, useReadyEffect } = useReadyState();
  const getNectarsList = useNectarsListWithCache();
  const scrollerCache = useNectarCacheScroller();
  const swipeCache = useNectarCacheSwipeList();
  const [isLoading, setLoading] = useState(scrollerCache.list.length === 0);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    (async function () {
      if (isPublic) {
        // NOTE: Public nectars scroller needs to wait until user leaves room.
        if (!isInRoom) {
          await loadNectars();
          setAsReady();
        }
      } else {
        // NOTE: Room nectar scroller can load immediately.
        await loadNectars();
        setAsReady();
      }
    })();
  }, [isInRoom]);

  useReadyEffect(() => {
    loadNectars();
  }, [activeLang]);

  async function loadNectars() {
    try {
      scrollerCache.update([], 0);
      setFetching(true);

      await getNectarsList({
        limit: 50,
        offset: -1,
        language: activeLang,
      });

      setLoading(false);
      setFetching(false);
    } catch (error) {
      console.error("Couldn't get nectars.", error);
    }
  }

  function handleScrollLoadMore() {
    if (scrollerCache.list.length < scrollerCache.total && !isFetching) {
      loadNectars();
    }
  }

  return (
    <NectarScroller
      isLoading={isLoading}
      nectars={scrollerCache.list}
      seeAllPath={!isInRoom ? "/discovery/nectars" : `/room/nectars/${currentRoomId}`}
      allLoaded={scrollerCache.list.length === scrollerCache.total}
      onNectarClick={(index: number) => {
        const nectar = scrollerCache.list[index];
        gaEventSelectItemNectar({
          nectar,
          listName: AnalyticsItemList.HOME, // TODO: condition for rooms
        });
        swipeCache.update(scrollerCache.list, scrollerCache.total);
        swipeCache.setSwipeIndex(index);
      }}
      onScrollNearEnd={handleScrollLoadMore}
    />
  );
}
