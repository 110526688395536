import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationModel } from "../../domains/app/app.types";
import { VideoFormat } from "../../domains/video/video.types";
import { getNotification } from "../../domains/notification/endpoints/getNotification";
import { t } from "i18next";
import { displayToast } from "../../components/app/AppToast";
import { androidOpen, isNativeAndroid } from "../../tools/android";
import { iosOpenNewTab, isNativeIOS } from "../../tools/ios";
import Loader from "../../components/Loader";

// NOTE: This page takes a notification and redirects to the related page.

export default function NotificationRouter() {
  const history = useHistory();
  const { id: notificationId } = useParams<{ id: string }>();

  useEffect(() => {
    loadNotification();
  }, []);

  async function loadNotification() {
    try {
      const notification = await getNotification(notificationId);
      redirect(notification);
    } catch (error: any) {
      console.error("Couldn't load notification.", error);
      if ("response" in error) {
        if (error.response.status === 401) {
          localStorage.setItem("_cache_notification", notificationId);
          displayToast(t("toast:fail.notificationNeedLogin"));
          return history.replace("/login");
        }

        if (error.response.status === 404) {
          displayToast(t("toast:fail.notificationNotFound"));
          return history.replace("/discovery");
        }
      }

      history.replace("/profile/notifications");
    }
  }

  function redirect(notification: NotificationModel) {
    const type = notification?.action?.notificationType;

    if ([
      "FollowActivity",
      "ContentNew",
      "ContentRecommended",
      "ContentShare",
    ].includes(type)) {
      return history.replace(`/discovery`);
    }

    if (type === "LemonCertifiedAnswered") return history.replace("/settings");

    if ([
      "FollowRequestReceived",
      "FollowRequestSent",
      "FollowRequestAccepted",
      "FollowUnfollow",
    ].includes(type)) {
      const emitter = notification.action.emitters[0] ?? "";
      return history.replace(`/profile/user/${emitter?.uid}`);
    }

    if (type === "DirectMessage") {
      const conversation = notification?.action?.conversation;
      return history.replace(
        `/profile/messages/conversation/${conversation?.id}`
      );
    }

    if (type === "ExternalLink") {
      history.replace("/profile/notifications");

      const notificationUrl = notification?.action?.url;
      if ([
        "app.juisci.com",
        "dev.juisci.com",
        "pro.juisci.com",
        "pro-dev.juisci.com",
      ].some(host => notificationUrl.includes(host))) {
        history.push(notificationUrl.split(".com")[1]);
      } else if (isNativeIOS) {
        iosOpenNewTab(notificationUrl);
      } else if (isNativeAndroid) {
        androidOpen(notificationUrl);
      } else {
        window.open(notificationUrl, "_blank");
      }
      return;
    }

    if (type === "ArticlePublished") {
      return history.replace(
        `/post/${notification.action.article?.slug}?notificationCenter=true`
      );
    }

    if (type === "VideoPublished") {
      const content = notification?.action?.content;
      return history.replace(
        !content
          ? "/discovery/videos"
          : content.videoFormat === VideoFormat.STORY
            ? `/video/story/${content.slug}`
            : `/video/${content.slug}`
      );
    }

    return history.replace("/profile/notifications");
  }

  return < Loader />;
}
