import { ReactChildren, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import SafeAreaBottomWrapper from "./SafeAreaBottomWrapper";
import styled from "styled-components";
import { ReactComponent as NavDiscoveryFillIcon } from "../../assets/icons/nav/nav-discovery-fill.svg";
import { ReactComponent as NavDiscoveryRegularIcon } from "../../assets/icons/nav/nav-discovery-regular.svg";
import { ReactComponent as NavForYouFillIcon } from "../../assets/icons/nav/nav-foryou-fill.svg";
import { ReactComponent as NavForYouRegularIcon } from "../../assets/icons/nav/nav-foryou-regular.svg";
import { ReactComponent as NavCongressFillIcon } from "../../assets/icons/nav/nav-congress-fill.svg";
import { ReactComponent as NavCongressRegularIcon } from "../../assets/icons/nav/nav-congress-regular.svg";

export default function RoomBottomNavbar() {
  const { t } = useLangContext();
  const { currentRoomId } = useRoomContext();

  return (
    <>
      <OuterContainer>
        <SafeAreaBottomWrapper>
          <InnerContainer>
            <NavLink to={`/room/discovery/${currentRoomId}`} icons={[<NavDiscoveryFillIcon />, <NavDiscoveryRegularIcon />]}>
              {t("common:nav.discovery")}
            </NavLink>

            <NavLink to={`/room/foryou/${currentRoomId}`} icons={[<NavForYouFillIcon />, <NavForYouRegularIcon />]}>
              {t("common:nav.forYou")}
            </NavLink>

            <NavLink to={`/room/conferences/${currentRoomId}`} icons={[<NavCongressFillIcon />, <NavCongressRegularIcon />]}>
              {t("common:nav.conferences")}
            </NavLink>
            {/* 
            <NavLink to="/search" icons={[<NavSearchYellowIcon />, <NavSearchGreyIcon />]} onClick={() => {
              // NOTE: We clear cached search data, considering users
              // start a new search cycle clicking this link.
              dispatch(clearCachedResults());
              dispatch(clearContextTab());
            }}>
              {t("common:nav.search")}
            </NavLink>
            */}
          </InnerContainer>
        </SafeAreaBottomWrapper>
      </OuterContainer>
    </>
  );
}

const OuterContainer = styled.div`
  background: #212121;
  box-shadow: 0px -4px 10px 0px rgba(33, 33, 33, 0.10);
`;

const InnerContainer = styled.div`
  padding: 8px 32px 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

function NavLink({ children, to, icons, preventNavigate, onClick }: {
  children: ReactNode | ReactChildren;
  to: string;
  icons: any[];
  preventNavigate?: boolean;
  onClick?: Function;
}) {
  const { pathname } = useLocation();
  const [activeIcon, inactiveIcon] = icons;
  const isActive = pathname.startsWith(to);

  return (
    <NavLinkContainer
      className={isActive ? "active" : ""}
      to={to} onClick={(e) => {
        if (preventNavigate) e.preventDefault();
        onClick && onClick();
      }}
    >
      {isActive ? activeIcon : inactiveIcon}
      <span>{children}</span>
    </NavLinkContainer>
  );
}

const NavLinkContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  svg {
    width: 28px;
    height: 28px;
  }
    
  svg > path {
    fill: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
  }

  span {
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
    text-align: center;
    font-family: Inter;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: -0.09px;
  }
  

  &.active {
    span {
      color: #fff;
    }
    
    svg > path {
      fill: #fff;
    }
  }
`;