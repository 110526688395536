import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useReadyState } from "../../../domains/app/hooks/useReadyState";
import { usePageScroll } from "../../../domains/app/hooks/usePageScroll";
import { ArticleCacheList, useArticleCache } from "../../../domains/article/hooks/useArticleCache";
import { useArticleSwipe } from "../../../domains/article/hooks/useArticleSwipe";
import { SpecialtyModel } from "../../../domains/app/app.types";
import { useLazyGetArticlesListQuery } from "../../../domains/article/endpoints/getArticlesList";
import { getItemTranslation } from "../../../domains/app/utils/getItemTranslation";
import { getStoredContentFilters } from "../../../domains/app/utils/contentFilters";
import disableScroll from "disable-scroll";
import { gaEventViewItemListArticle } from "../../../tools/analytics/articleAnalytics";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { displayToast } from "../../../components/app/AppToast";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack, NavLang } from "../../../components/app/headers/layout";
import BottomNavbar from "../../../components/app/BottomNavbar";
import FadeIn from "react-fade-in/lib/FadeIn";
import SkeletonCard from "../../../components/skeleton/SkeletonCard";
import NoContent from "../../../components/NoContent";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemList from "../../../components/app/ItemList";
import ContentFilterButton from "../../../components/filters/ContentFilterButton";
import ArticleCard from "../../../components/cards/ArticleCard";
import styled from "styled-components";
import SpecialtyFilterPanel from "../../../components/filters/SpecialtyFilterPanel";
import SubSpecialtyFilterPanel from "../../../components/filters/SubSpecialtyFilterPanel";

export default function HomeContentPublicationsPage() {
  const location = useLocation<{ specialty?: SpecialtyModel }>();
  const params = useParams<{ slug: string }>();
  const { activeLang, t } = useLangContext();
  const articleCache = useArticleCache();
  const cachedArticles = articleCache.cacheLists[ArticleCacheList.CONTENT_LIST];
  const { setAsReady, useReadyEffect } = useReadyState();
  const { savePageScrollY, restorePageScrollY } = usePageScroll();
  const { setSwipeCache } = useArticleSwipe();
  const [getArticlesList] = useLazyGetArticlesListQuery();
  const [isLoading, setLoading] = useState(true);
  const [articles, setArticles] = useState<any[]>([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [specialtyFilters, setSpecialtyFilters] = useState<string[]>(
    location.state?.specialty ? [location.state.specialty._id] : getStoredContentFilters().specialtyIds ?? []
  );
  const [subSpecialtyFilters, setSubSpecialtyFilters] = useState<string[]>(getStoredContentFilters().subSpecialtyIds ?? []);
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false);
  const isAllSpecialties = !params.slug;

  useEffect(() => {
    (async function () {
      disableScroll.off();
      if (articles.length === 0 && cachedArticles.length >= 20) {
        setArticles(cachedArticles);
        setTotalArticles(1000); // NOTE: Force fetch when scrolling to the bottom of the list.

        setLoading(false);
        restorePageScrollY();
      } else {
        scrollTo(0, 0);
        loadArticles(true);
      }
      setAsReady();
    })();
  }, []);

  useReadyEffect(() => {
    scrollTo(0, 0);
    loadArticles(true);
  }, [activeLang]);

  useReadyEffect(() => {
    loadArticles(true);
  }, [specialtyFilters, subSpecialtyFilters]);

  useEffect(() => {
    if (articles.length > 0) {
      articleCache.saveList(ArticleCacheList.CONTENT_LIST, articles);
    }

    gaEventViewItemListArticle({
      articles,
      listName: AnalyticsItemList.HOME,
    });
  }, [articles]);

  async function loadArticles(resetList = false) {
    try {
      const fetchParams: any = {
        limit: 20,
        offset: resetList ? 0 : articles.length,
        language: activeLang,
      };

      if (specialtyFilters.length > 0) {
        fetchParams.medicalSpecialties = specialtyFilters.join(",");
      }

      if (subSpecialtyFilters.length > 0) {
        fetchParams.tags = subSpecialtyFilters.join(",");
      }

      setLoading(true);
      await getArticlesList(fetchParams)
        .unwrap()
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...articles, ...docs];
          setArticles(newList);
          setTotalArticles(total);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't fetch articles.", error);
      displayToast(t("error:default"));
    }
  }

  function handleChangeFilters() {
    if (isAllSpecialties) {
      setSpecialtyFilters(getStoredContentFilters().specialtyIds ?? specialtyFilters);
    } else {
      setSubSpecialtyFilters(getStoredContentFilters().subSpecialtyIds ?? subSpecialtyFilters);
    }
  }

  function handleExit() {
    articleCache.saveList(ArticleCacheList.CONTENT_LIST, []);
    history.back();
  }

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack onClick={handleExit} /></HeaderLinks>
          <HeaderTitle>
            {location.state?.specialty ? getItemTranslation(location.state?.specialty) : t("content:type.publications")}
          </HeaderTitle>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <ContentFilterButton
          active={params.slug
            ? subSpecialtyFilters ? subSpecialtyFilters.length > 0 : false
            : specialtyFilters ? specialtyFilters.length > 0 : false
          }
          onClick={() => setFilterPanelVisible(true)}
        />

        <div style={{ flex: 1 }}>
          {isLoading ? (
            <FadeIn key="loading">
              {[...Array(10)].map((_, index) => (
                <SkeletonCard key={`${index}--skeleton-card`} />
              ))}
            </FadeIn>
          ) : !articles.length ? (
            <NoContent
              specialty={params.slug
                ? getItemTranslation(location.state.specialty as SpecialtyModel)
                : t("content:yourSearch")
              }
            />
          ) : (
            <FadeIn key="content">
              <InfiniteScroll
                dataLength={articles.length}
                next={loadArticles}
                hasMore={articles.length < totalArticles}
                loader={null}
              >
                <ItemList>
                  {articles.map((article, index) => (
                    <ArticleCard
                      variant="white"
                      key={article.id}
                      article={article}
                      analyticsListName={AnalyticsItemList.HOME}
                      onClick={() => {
                        setSwipeCache(articles, index);
                        savePageScrollY();
                      }}
                    />
                  ))}
                </ItemList>
              </InfiniteScroll>
            </FadeIn>
          )}
        </div>

        <BottomNavbar />
      </Wrapper>

      {isAllSpecialties ? (
        <SpecialtyFilterPanel
          isOpen={isFilterPanelVisible}
          onClose={() => setFilterPanelVisible(false)}
          onSave={() => handleChangeFilters()}
        />
      ) : (
        <SubSpecialtyFilterPanel
          specialty={location.state.specialty as SpecialtyModel}
          isOpen={isFilterPanelVisible}
          onClose={() => setFilterPanelVisible(false)}
          onSave={() => handleChangeFilters()}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: var(--BRAND-CORE-Juisci-Yellow, #FFC408);
`;
