import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { useLazyGetPlaylistsQuery } from "../../redux/user/user.service";
import { createPlaylist } from "../../domains/playlist/endpoints/createPlaylist";
import CustomSheet from "../CustomSheet";
import { CustomButton } from "../global";
import { isNativeIOS } from "../../tools/ios";
import styled from "styled-components";
import { Trans } from "react-i18next";
import CustomIcon from "../CustomIcon";
import { CustomInput } from "../CustomInput";
import PreviewCard from "../profile/PreviewCard";
import { Checkbox } from "antd-mobile";
import { displayToast } from "./AppToast";
import { t } from "i18next";
import { ReactComponent as PipeIcon } from "../../assets/core/pipe-icon.svg";
import axios from "axios";

export default function PlaylistPanel({
  isVisible,
  contentToAdd,
  onClose,
  onSave,
}: {
  isVisible: boolean;
  contentToAdd: any;
  onClose: Function;
  onSave: Function;
}) {
  const history = useHistory();
  const { currentUser, playlists: userPlaylists } = useCurrentUser();
  const [getPlaylists] = useLazyGetPlaylistsQuery();
  const [isCreatingPlaylist, setCreatingPlaylist] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const [isSaveInProgress, setSaveInProgress] = useState(false);
  const [startingPlaylistsIds, setStartingPlaylistsIds] = useState<string[]>([]);
  const [selectedPlaylistsIds, setSelectedPlaylistsIds] = useState<string[]>([]);

  useEffect(() => {
    (async function () {
      if (currentUser) {
        await getPlaylists({})
          .unwrap()
          .catch((error) => {
            console.error("Couldn't get user playlists.", error);
          });
      }
    })();
  }, []);

  useEffect(() => {
    if (isVisible) {
      if (!currentUser) {
        return history.push("/login");
      }

      const playlistsWithContent = userPlaylists
        .filter((playlist: PlaylistModel) => {
          return [...playlist.playlist, ...playlist.playlistContent].some((content: any) => {
            return content.id === contentToAdd.id || content._id === contentToAdd._id
          })
        });

      setStartingPlaylistsIds(playlistsWithContent.map(({ _id }: { _id: string }) => _id));
      setSelectedPlaylistsIds(playlistsWithContent.map(({ _id }: { _id: string }) => _id));
    }
  }, [isVisible]);

  function handleCheckPlaylist(contentId: string) {
    if (selectedPlaylistsIds.includes(contentId)) {
      setSelectedPlaylistsIds(selectedPlaylistsIds.filter(id => id !== contentId));
    } else {
      setSelectedPlaylistsIds([...selectedPlaylistsIds, contentId]);
    }
  }

  async function handleCreatePlaylist() {
    try {
      if (!newPlaylistName.length) return;

      setSaveInProgress(true);
      const playlist = await createPlaylist({ title: newPlaylistName });

      displayToast(t("toast:success.playlistCreated"));

      await onSave({ add: [playlist._id], remove: [] });
      setCreatingPlaylist(false);
      setSaveInProgress(false);
      setNewPlaylistName("");
      onClose();
    } catch (error) {
      console.error("Couldn't create playlist.", error);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        displayToast(t("toast:fail.playlistNameAlreadyUsed"), "error");
      } else {
        displayToast(t("error:default"));
      }
      setSaveInProgress(false);
    }
  }

  function renderNoPlaylists() {
    return (
      <CustomSheet
        onChange={onClose}
        title={t("common:panel.playlist.label.create")}
        show={isVisible}
        bottomContent={
          <>
            <CustomButton
              className="black"
              style={{ marginBottom: isNativeIOS ? 5 : 0 }}
              onClick={() => setCreatingPlaylist(true)}
            >
              {t("common:panel.playlist.label.create")}
            </CustomButton>
          </>
        }
      >
        <NoPlaylistsMessage>
          <PipeIcon />
          <p>
            <Trans
              i18nKey="common:panel.playlist.createPlaylist.emptyStateText"
              values={{ name: currentUser.firstname ?? t("common:defaultUserName") }}
              components={{ b: <b /> }}
            />
          </p>
        </NoPlaylistsMessage>
      </CustomSheet>
    );
  }

  function renderCreatePlaylist() {
    return (
      <CustomSheet
        show={isVisible && isCreatingPlaylist}
        title={t("common:panel.playlist.createPlaylist.title")}
        onChange={() => {
          setCreatingPlaylist(false);
          onClose();
        }}
        backContent={
          <CustomIcon
            iconName="back-arrow"
            style={{ margin: "0 5px -15px" }}
            onClick={() => setCreatingPlaylist(false)}
          />
        }
        bottomContent={
          <>
            <CustomButton
              className={isSaveInProgress
                ? "loading disabled"
                : !!newPlaylistName.length
                  ? "pink"
                  : "disabled"
              }
              style={{
                textTransform: "capitalize",
                marginBottom: isNativeIOS ? 5 : 0,
              }}
              onClick={handleCreatePlaylist}
              disabled={newPlaylistName.length === 0}
            >
              {t("common:action.save")}
            </CustomButton>
          </>
        }
      >
        <div style={{ overflow: "hidden", padding: "30px 0" }}>
          <CustomInput
            value={newPlaylistName}
            onInput={(e: ChangeEvent<HTMLInputElement>) => setNewPlaylistName(e.target.value)}
            placeholder={t("common:panel.playlist.createPlaylist.namePlaceholder")}
          />
        </div>
      </CustomSheet>
    );
  }

  if (!isVisible) return null;

  if (isCreatingPlaylist) {
    return renderCreatePlaylist();
  }

  if (!userPlaylists.length) {
    return renderNoPlaylists();
  }

  return (
    <CustomSheet
      show={isVisible}
      title={t("common:panel.playlist.addToPlaylist")}
      onChange={onClose}
      bottomContent={
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <CustomButton className="black" onClick={() => setCreatingPlaylist(true)}>
            {t("common:panel.playlist.createPlaylist.title")}
          </CustomButton>
          <CustomButton
            className={isSaveInProgress
              ? "loading disabled"
              : "pink"
            }
            style={{
              textTransform: "capitalize",
              marginBottom: isNativeIOS ? 5 : 0,
            }}
            onClick={async () => {
              setSaveInProgress(true);

              const add = userPlaylists.filter((playlist: any) => {
                return selectedPlaylistsIds.includes(playlist._id)
                  && !startingPlaylistsIds.includes(playlist._id);
              }).map((playlist: any) => playlist._id);

              const remove = userPlaylists.filter((playlist: any) => {
                return !selectedPlaylistsIds.includes(playlist._id)
                  && startingPlaylistsIds.includes(playlist._id);
              }).map((playlist: any) => playlist._id);

              await onSave({ add, remove });
              setSaveInProgress(false);
              onClose();
            }}
          >
            {t("common:action.save")}
          </CustomButton>
        </div>
      }
    >
      <PlaylistsContainer>
        <PreviewCard content={contentToAdd} />
        <span className="add-message">{t("common:panel.playlist.addPublicationToPlaylist")}</span>
        {userPlaylists &&
          userPlaylists?.map((playlist: PlaylistModel) => {
            return (
              <div key={playlist._id}>
                <Checkbox.AgreeItem
                  onChange={() => handleCheckPlaylist(playlist._id)}
                  checked={selectedPlaylistsIds.includes(playlist._id)}
                >
                  <span className="playlist-name">{playlist.title}</span>
                </Checkbox.AgreeItem>
              </div>
            );
          })}
      </PlaylistsContainer>
    </CustomSheet>
  );
}

const NoPlaylistsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  svg {
    width: 100px;
    height: 100px;
    margin-top: 20px;
  }

  p {
    margin: 0 32px;
    text-align: center;
    font-size: 15px;
  }
`;

const PlaylistsContainer = styled.div`
  padding-bottom: 100px;
  
  .add-message {
    display: block;
    margin: 35px 0 20px;
  }

  .am-checkbox-agree {
    margin-left: 0;
    padding-left: 0;

    .playlist-name {
      font-family: "Inter";
      font-size: 18px;
      font-weight: 600;
      color: "#212121";
    }
  }
`;