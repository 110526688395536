import { SpecialtyModel } from "../../domains/app/app.types";
import { VideoFormat, VideoModel } from "../../domains/video/video.types";
import { AnalyticsItemCategory, AnalyticsItemVariant } from "./analytics.types";
import { gaEventSelectItem, gaEventViewItem, gaEventViewItemList } from "./analyticsEvents";
import { gaPushEvent, gaPushEventDelay } from "./gaPushEvent";

enum Event {
  VIDEO_PLAYED = "video_played",
  VIDEO_LIKE = "video_like",
  VIDEO_SAVE = "video_save",
  VIDEO_SHARE = "video_share",
}

interface VideoItemPayload {
  video: VideoModel;
  listName: string;
}

interface VideoItemListPayload {
  videos: VideoModel[];
  listName: string;
}

function convertVideoToItemData(payload: VideoItemPayload) {
  const { video, listName } = payload;
  return {
    item_id: video._id,
    item_name: video.title,
    item_brand: video.company?.name ?? null,
    item_category: video.medical_specialties?.map((spe: SpecialtyModel) => {
      return spe.uid;
    }).join(","),
    item_category2: AnalyticsItemCategory.STANDARD,
    item_variant: video.videoFormat === VideoFormat.STORY
      ? AnalyticsItemVariant.VIDEO_STORY
      : AnalyticsItemVariant.VIDEO_ARTICLE,
    item_list_name: listName,
  };
}

export function gaEventSelectItemVideo(payload: VideoItemPayload) {
  gaEventSelectItem(convertVideoToItemData(payload));
}

export function gaEventViewItemVideo(payload: VideoItemPayload) {
  gaEventViewItem(convertVideoToItemData(payload));
}

export function gaEventViewItemListVideo(payload: VideoItemListPayload) {
  gaEventViewItemList(payload.videos.map((video) => {
    return convertVideoToItemData({
      video,
      listName: payload.listName,
    });
  }));
}

export function gaEventVideoPlay(payload: VideoItemPayload) {
  gaPushEventDelay(
    Event.VIDEO_PLAYED,
    {
      ecommerce: {
        items: [convertVideoToItemData(payload)],
      },
    },
  );
}

export function gaEventVideoLike(payload: VideoItemPayload) {
  gaPushEvent(
    Event.VIDEO_LIKE,
    {
      ecommerce: {
        items: [convertVideoToItemData(payload)],
      },
    },
  );
}

export function gaEventVideoSave(payload: VideoItemPayload) {
  gaPushEvent(
    Event.VIDEO_SAVE,
    {
      ecommerce: {
        items: [convertVideoToItemData(payload)],
      },
    },
  );
}

export function gaEventVideoShare(payload: VideoItemPayload) {
  gaPushEvent(
    Event.VIDEO_SHARE,
    {
      ecommerce: {
        items: [convertVideoToItemData(payload)],
      },
    },
  );
}
