import React, { useState, useEffect } from "react";
import styled from "styled-components";
import disableScroll from "disable-scroll";
import { CustomInput } from "../../components/CustomInput";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTagline,
  HeaderTitle,
  NavBack,
} from "../../components/app/headers/layout";
import { createPlaylist } from "../../domains/playlist/endpoints/createPlaylist";
import { displayToast } from "../../components/app/AppToast";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import AppButton from "../../components/app/AppButton";

export default function ProfileCreatePlaylist() {
  const history = useHistory();
  const { t } = useLangContext();
  const [loading, setLoading] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
    disableScroll.on();
    initialize();
  }, [history]);

  async function initialize() {
    try {
      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.default"),
      });
      if (isRedirected) return;
    } catch (error) {
      console.error("Error during initialization", error);
    }
  }

  async function handleCreate() {
    setLoading(true);

    if (playlistName.length > 0) {
      const data = { title: playlistName };

      try {
        const createReq = await createPlaylist(data);

        if (createReq.error) {
          displayToast(t("toast:fail.playlistNameAlreadyUsed"), "error");
          setLoading(false);
          return;
        }

        displayToast(t("toast:success.playlistCreated"), "success");
        await getCurrentUser();
        history.goBack();
      } catch (error) {
        console.error("Error creating playlist:", error);
        displayToast(t("toast:fail.playlistCreationError"), "error");
      }
    } else {
      displayToast(t("toast:fail.playlistNameEmpty"), "error");
    }

    setLoading(false);
  }

  return (
    <Wrapper>
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <HeaderTitle>
          <HeaderTagline>
            {t("common:panel.playlist.createPlaylist.title")}
          </HeaderTagline>
          {playlistName.length > 0
            ? playlistName
            : t("common:panel.playlist.createPlaylist.newPlaylistName")}
        </HeaderTitle>
        <HeaderLinks />
      </HeaderBoxAutoSafe>

      <Container>
        <CustomInput
          placeholder={t("common:panel.playlist.createPlaylist.title")}
          value={playlistName}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlaylistName(e.target.value)
          }
        />
        <hr style={{ margin: "30px 0" }} />
        <AppButton
          className={loading ? "loading disabled" : ""}
          onClick={handleCreate}
        >
          {t("common:action.create")}
        </AppButton>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  background: #ecf0f5;
`;

const Container = styled.div`
  padding: 30px;
`;
