import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch } from "../../redux";
import { VideoModel } from "../../domains/video/video.types";
import { viewContent } from "../../domains/content/endpoints/viewContent";
import { getCompany } from "../../domains/company/endpoints/getCompany";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { useLikeVideo } from "../../domains/user/hooks/useLikeVideo";
import { useSaveVideo } from "../../domains/user/hooks/useSaveVideo";
import { useShareVideo } from "../../domains/user/hooks/useShareVideo";
import { isNativeIOS } from "../../tools/ios";
import { setUser } from "../../redux/user/user.reducer";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventVideoPlay } from "../../tools/analytics/videoAnalytics";
import ApiVideoPlayer from "@api.video/react-player";
import { Slider } from "@mui/material";
import ProfilePicture from "../ProfilePicture";
import PlaylistPanel from "../app/PlaylistPanel";
import styled from "styled-components";
import { ReactComponent as VolumeOnIcon } from "../../assets/icons/video-player/volume-on.svg";
import { ReactComponent as VolumeOffIcon } from "../../assets/icons/video-player/volume-off.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause-fill.svg";
import { ReactComponent as LikeIcon } from "../../assets/icons/card_like.svg";
import { ReactComponent as LikeActiveIcon } from "../../assets/icons/card_like_active.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/card_save.svg";
import { ReactComponent as SaveActiveIcon } from "../../assets/icons/card_save_active.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/card_share.svg";
import { ReactComponent as EditorialIcon } from "../../assets/icons/card_editorial.svg";
import { ReactComponent as ExternalLinkIcon } from "../../assets/icons/external-link-pink.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-fill.svg";

// TODO: This should be renamed to something else: VideoStory, VideoStoryPlayer, etc.

export default function StoryVideo({
  video,
  height,
  isVisible,
  analyticsListName,
  onEnd,
  onClickLink,
}: {
  video: VideoModel;
  height: number;
  isVisible: boolean;
  analyticsListName: AnalyticsItemList;
  onEnd: Function;
  onClickLink?: Function;
}) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUser } = useCurrentUser();
  const [isPlayerReady, setPlayerReady] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const [hasPlayedOnce, setPlayedOnce] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const [trackDuration, setTrackDuration] = useState(0);
  const [sliderTime, setSliderTime] = useState(0);
  const listName = location.pathname.includes("foryou")
    ? AnalyticsItemList.FORYOU
    : AnalyticsItemList.STORY_LIST_PAGE;
  const { isLiked, toggleLike } = useLikeVideo(video, listName);
  const { isSaved, toggleSaveToPlaylists } = useSaveVideo(video, listName);
  const shareVideo = useShareVideo(video, listName);
  const playerRef = useRef<any>(null);
  const player = playerRef.current;
  const [publisherData, setPublisherData] = useState<{
    path: string | null;
    image: string | undefined | null;
    publisher: string;
  } | null>(null);

  useEffect(() => {
    fetchPublisherData();
    if (isPlayerReady) {
      player.setCurrentTime(0);
      if (isVisible) {
        setPlaying(true);
        if (!isNativeIOS) setMuted(false);

        (async function () {
          try {
            await viewContent(video._id);
            // TODO: Analytics (page doesn't exist in list).
          } catch (error) {
            console.error("Couldn't view video");
          }
        })();
      } else {
        setPlaying(false);
        setMuted(true);
      }
    }
  }, [isVisible, isPlayerReady]);

  useEffect(() => {
    if (isVisible) handleViewVideo();
  }, [isVisible]);

  useEffect(() => {
    isPlaying ? player?.play() : player?.pause();
  }, [isPlaying]);

  useEffect(() => {
    isMuted ? player?.mute() : player?.unmute();
  }, [isMuted]);

  useEffect(() => {
    setPlaying(!isPlaylistsPanelVisible);
  }, [isPlaylistsPanelVisible]);

  async function fetchPublisherData() {
    const data = await getPublisherData();
    setPublisherData(data);
  }

  function onPlayerReady() {
    setPlayerReady(true);
  }

  async function getPublisherData() {
    switch (video.publisher) {
      case "journal":
        return {
          path: `/journal/${video.journal?.uid}`,
          image: video.journal?.image?.url,
          publisher: "journal",
        };
      case "congress":
        return {
          path: null,
          image: video.congress?.image?.url,
          publisher: "congress",
        };
      case "user":
        return {
          path: `/profile/user/${video.user?.uid}`,
          image: null,
          publisher: "user",
        };
      default:
        if (video.company?._id) {
          try {
            const companyData = await getCompany(video.company._id);
            return {
              path: `/company/${companyData._id}`,
              image: companyData.images?.[0]?.url,
              publisher: "company",
            };
          } catch (error) {
            console.error("Couldn't get company data.", error);
          }
        }
        // NOTE: Fallback
        return {
          path: `/company/${video.company?._id || ""}`,
          image: video.company?.images?.[0]?.url || null,
          publisher: "company",
        };
    }
  }

  function handleViewVideo() {
    if (!hasPlayedOnce) {
      gaEventVideoPlay({
        video,
        listName: analyticsListName,
      });

      setPlayedOnce(true);
    }
  }

  function onTimeUpdate(time: number) {
    // NOTE: There is a bug here where this callback function
    // doesn't update props. So all videos are actually playing...
    setSliderTime(time);
  }

  function handleClickWrapper() {
    if (isMuted) {
      setMuted(false);
      return;
    }

    setPlaying(!isPlaying);
  }

  function handleVisitLink() {
    onClickLink && onClickLink();
    history.replace(`/video/story/${video.slug}`);
  }

  return (
    <>
      <Wrapper
        // className={this.state.started ? "started" : ""}
        style={{ height }}
      >
        <ClickOverlay onClick={handleClickWrapper} />

        <FeedbackIconWrapper className={!isMuted ? "hide" : ""}>
          {!isMuted ? <VolumeOnIcon /> : <VolumeOffIcon />}
        </FeedbackIconWrapper>

        {/* <FeedbackIconWrapper className={isPlaying ? "hide" : ""}>
          <PauseIcon />
        </FeedbackIconWrapper> */}

        {/* {this.state.started && ( */}
        <Actions>
          <div
            className={`publisher-logo ${video.publisher === "user" ? "is-user" : ""
              }`}
            onClick={() => {
              handleVisitLink();
              publisherData?.path && history.push(publisherData.path);
            }}
          >
            {publisherData?.publisher === "user" ? (
              <ProfilePicture height={56} user={video.user} />
            ) : publisherData?.image ? (
              <img src={publisherData.image} alt="" />
            ) : null}
          </div>

          <div className="action-icon" onClick={toggleLike}>
            {isLiked ? <LikeActiveIcon /> : <LikeIcon />}
          </div>
          <div
            className="action-icon"
            onClick={() => setPlaylistsPanelVisible(true)}
          >
            {isSaved ? <SaveActiveIcon /> : <SaveIcon />}
          </div>
          <div className="action-icon" onClick={shareVideo}>
            <ShareIcon />
          </div>

          {video?.associatedArticles &&
            !!video?.associatedArticles[0]?.slug && (
              <Link
                onClick={() => handleVisitLink()}
                to={"/post/" + video.associatedArticles[0].slug}
              >
                <EditorialIcon />
              </Link>
            )}

          {video.externalLink && (
            <a className="action-icon" target="_blank" href={video.externalLink}>
              <ExternalLinkIcon />
            </a>
          )}
        </Actions>
        {/*  )} */}

        <ApiVideoPlayer
          ref={playerRef}
          video={{ id: video.apiVideo?.videoId as string }}
          style={{
            width: "auto",
            height: "100%",
          }}
          videoStyleObjectFit={window.innerWidth > 550 ? "fill" : "cover"}
          chromeless
          autoplay
          muted={isMuted}
          onReady={onPlayerReady}
          // onFirstPlay={() => setStarted(true)}
          onEnded={() => onEnd()}
          onTimeUpdate={onTimeUpdate}
          onDurationChange={(duration) => setTrackDuration(duration)}
        />

        <PlayerSliderWrapper>
          <Slider
            size="small"
            // sx={{
            // visibility: this.state.started ? "isVisible" : "hidden",
            // }}
            value={
              trackDuration && sliderTime
                ? (sliderTime / trackDuration) * 100
                : 0
            }
            onChange={(_, value: any) => {
              const time = (value * trackDuration) / 100;
              player.setCurrentTime(time);
            }}
            onChangeCommitted={(_, value: any) => {
              player.setCurrentTime((value * trackDuration) / 100);
            }}
            step={2}
          />
          <div onClick={() => setPlaying(!isPlaying)}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </div>
        </PlayerSliderWrapper>
      </Wrapper>

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={video}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={async ({
          add,
          remove,
        }: {
          add: string[];
          remove: string[];
        }) => {
          await toggleSaveToPlaylists({ add, remove });
        }}
      />
    </>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  scroll-snap-align: start;
  scroll-snap-stop: always;
`;

const ClickOverlay = styled.div`
  box-sizing: border-box;
  background: transparent;
  position: absolute;
  z-index: 900 !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const FeedbackIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  opacity: 1;
  transition: opacity ease-out 0.3s;

  svg {
    width: 54px;
    height: 54px;
  }

  &.hide {
    opacity: 0;
  }
`;

const PlayerSliderWrapper = styled.div`
  position: absolute;
  z-index: 901 !important;
  bottom: 3vh;
  left: 28px;
  right: 28px;
  background: transparent;
  box-sizing: border-box;
  padding: 16px 0;
  display: flex;
  align-items: center;
  gap: 16px;

  .MuiSlider-root {
    color: #000;
    width: 90%;
    border-radius: 0;
    margin: auto;
    display: block;
    height: 3px;
  }

  .MuiSlider-rail {
    color: #fff;
    height: 3px;
  }

  .MuiSlider-track {
    height: 3px;
  }

  .MuiSlider-thumb {
    color: #000;
    width: 22px;
    height: 22px;
  }
`;

const Actions = styled.div`
  position: absolute;
  right: 10px;
  bottom: 126px;
  z-index: 901 !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  img,
  .action-icon svg {
    width: 40px;
    height: 40px;
  }

  .publisher-logo {
    box-sizing: border-box;
    background: #fff;
    width: 64px;
    height: 64px;
    object-fit: contain;
    overflow: hidden;
    border-radius: 112px;
    padding: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.is-user {
      padding: 0;

      img {
        object-fit: cover;
      }
    }
  }
`;
