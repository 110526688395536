import { ReactChildren, ReactNode, useState, useRef, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SafeAreaTopWrapper from "../SafeAreaTopWrapper";
import LanguagePanel from "../LanguagePanel";
import styled from "styled-components";
import Lottie from "lottie-react";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { ReactComponent as JuisciLogo } from "../../../assets/core/juisci-logo.svg";
import { ReactComponent as NavHomeIcon } from "../../../assets/icons/nav/nav-home.svg";
import { ReactComponent as NavSettingsWhiteIcon } from "../../../assets/icons/nav/nav-settings-white.svg";
import { ReactComponent as NavSettingsYellowIcon } from "../../../assets/icons/nav/nav-settings-yellow.svg";
import { ReactComponent as NavBackIcon } from "../../../assets/icons/arrow-left-circle-yellow.svg";
import { ReactComponent as NavLangIcon } from "../../../assets/icons/nav/nav-lang.svg";
import { ReactComponent as NavOptionsIcon } from "../../../assets/icons/dots-circle-red.svg";
import { ReactComponent as NavRoomsBlackIcon } from "../../../assets/icons/nav/nav-rooms-black.svg";
import { ReactComponent as NavShareIcon } from "../../../assets/icons/nav/nav-share.svg";
import { ReactComponent as NavChatWhiteIcon } from "../../../assets/icons/nav/nav-chat-white.svg";
import { ReactComponent as NavChatYellowIcon } from "../../../assets/icons/nav/nav-chat-yellow.svg";
import { ReactComponent as NavBellWhiteIcon } from "../../../assets/icons/nav/nav-bell-white.svg";
import { ReactComponent as NavBellYellowIcon } from "../../../assets/icons/nav/nav-bell-yellow.svg";
import AnimatedRoomsIcon from "../../../assets/animations/room-button.json";

export function HeaderBoxAutoSafe({
  children,
  transparent,
}: {
  children?: ReactNode | ReactChildren;
  transparent?: boolean;
}) {
  return (
    <BaseHeaderOuterContainer className={transparent ? "transparent" : ""}>
      <SafeAreaTopWrapper float={transparent}>
        <BaseHeaderInnerContainer>{children}</BaseHeaderInnerContainer>
      </SafeAreaTopWrapper>
    </BaseHeaderOuterContainer>
  );
}

// NOTE: Only use when safe area is managed by another component,
// like a wrapper. Use HeaderBoxAutoSafe instead.
export function HeaderBoxSimple({
  children,
}: {
  children: ReactNode | ReactChildren;
}) {
  return (
    <BaseHeaderOuterContainer>
      <BaseHeaderInnerContainer>{children}</BaseHeaderInnerContainer>
    </BaseHeaderOuterContainer>
  );
}

const BaseHeaderOuterContainer = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(33, 33, 33, 0.1);

  &.transparent {
    background: none;
    box-shadow: none;
  }

  hr {
    border-color: #ecf0f5;
  }
`;

const BaseHeaderInnerContainer = styled.nav`
  padding: 11px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLinks = styled.div`
  min-width: 32px;
  display: flex;
  align-items: center;
  gap: 16px;

  a {
    display: flex;
  }

  svg {
    width: 32px;
    height: 32px;
  }
`;

export function HeaderLogoTitleTagline({
  logo,
  title,
  tagline,
}: {
  logo: string;
  title: string;
  tagline: string;
}) {
  const { t } = useLangContext();
  const roomNameRef = useRef<HTMLSpanElement>(null);
  const [isTwoLines, setIsTwoLines] = useState(false);

  // CHECK: is there a better way of doing this?
  useEffect(() => {
    const roomNameElement = roomNameRef.current;
    if (roomNameElement) {
      // Get the width of the element with white-space: nowrap
      roomNameElement.style.whiteSpace = "nowrap";
      const singleLineHeight = roomNameElement.scrollHeight;

      // Now allow the element to wrap to calculate actual height
      roomNameElement.style.whiteSpace = "normal";
      const wrappedHeight = roomNameElement.scrollHeight;

      // Reset white-space back to its original setting
      roomNameElement.style.whiteSpace = "";

      // If the height increases when wrapping, it's more than one line
      const isOverflowing = wrappedHeight > singleLineHeight;
      setIsTwoLines(isOverflowing);
    }
  }, [title]);

  return (
    <HeaderLogoTitleTaglineStyled>
      {logo ? (
        <img className="logo" src={logo} alt={title} />
      ) : (
        <LogoPlaceholder />
      )}
      <div className="room-info">
        <span
          className={`room-name ${isTwoLines ? "two-lines" : ""}`}
          ref={roomNameRef}
        >
          {title}
        </span>
        <span className="room-org">
          {t("common:by")} {tagline}
        </span>
      </div>
    </HeaderLogoTitleTaglineStyled>
  );
}

const HeaderLogoTitleTaglineStyled = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
  margin: 0 16px;
  min-width: 0;

  .logo {
    object-fit: contain;
    width: 30px;
    height: 30px;
    border: 1px solid #d2dce2;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .room-info {
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex-grow: 1;
    overflow: hidden;
  }

  .room-name {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 3px;

    &.two-lines {
      font-size: 10px;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .room-org {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 8px;
    font-weight: 400;
  }
`;

const LogoPlaceholder = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e5e5e5;
`;

// NOTE: remove?
export const HeaderTitle = styled.div`
  color: var(--SECONDARY-GREY-SHADES-Bluegrey-7, #313b42);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.16px;
`;

export const HeaderTagline = styled.div`
  color: var(--SECONDARY-GREY-SHADES-Bluegrey-7, #313b42);
  text-align: center;
  text-transform: uppercase;
  font-family: Inter;
  font-size: 6px;
  font-weight: 400;
  line-height: 6px; /* 100% */
  letter-spacing: 0.96px;
`;

export function HeaderLogo() {
  return (
    <LogoWrapper>
      <JuisciLogo />
    </LogoWrapper>
  );
}

const LogoWrapper = styled.div`
  margin: auto;

  svg {
    display: block;
    height: 34px;
  }
`;

export function NavHome() {
  return (
    <Link to="/discovery">
      <NavHomeIcon />
    </Link>
  );
}

export function NavSettings() {
  const { pathname } = useLocation();
  return (
    <Link to="/settings">
      {pathname.startsWith("/settings") ? (
        <NavSettingsYellowIcon />
      ) : (
        <NavSettingsWhiteIcon />
      )}
    </Link>
  );
}

export function NavNotifications() {
  const { pathname } = useLocation();
  return (
    <Link to="/profile/notifications">
      {pathname.startsWith("/profile/notifications") ? (
        <NavBellYellowIcon />
      ) : (
        <NavBellWhiteIcon />
      )}
    </Link>
  );
}

export function NavBack({ to, onClick }: { to?: string; onClick?: Function }) {
  const history = useHistory();

  return (
    <NavBackIcon
      onClick={() => {
        if (to) {
          history.push(to);
        } else if (onClick) {
          onClick();
        } else {
          if (history.length <= 1) {
            history.replace("/discovery");
          } else {
            history.goBack();
          }
        }
      }}
    />
  );
}

export function NavOptions({ onClick }: { onClick?: Function }) {
  return <NavOptionsIcon onClick={() => onClick && onClick()} />;
}

export function NavLang() {
  const [isPanelVisible, setPanelVisible] = useState(false);

  return (
    <>
      <NavLangIcon onClick={() => setPanelVisible(true)} />
      <LanguagePanel
        isVisible={isPanelVisible}
        onClose={() => setPanelVisible(false)}
      />
    </>
  );
}

export function NavRooms({ onClick }: { onClick?: Function }) {
  return (
    <Lottie
      animationData={AnimatedRoomsIcon}
      onClick={() => onClick && onClick()}
      style={{ width: "32px", height: "32px" }}
      loop={true}
    />
  );
}

export function NavShare({ onClick }: { onClick?: Function }) {
  return <NavShareIcon onClick={() => onClick && onClick()} />;
}

export function NavChat({ onClick }: { onClick?: Function }) {
  const { pathname } = useLocation();
  return pathname.startsWith("/profile/messages") ? (
    <NavChatYellowIcon onClick={() => onClick && onClick()} />
  ) : (
    <NavChatWhiteIcon onClick={() => onClick && onClick()} />
  );
}
