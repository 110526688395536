import { getApiRequestService } from "../../../services/index.services";

export async function shareContent(articleId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(`/content/share/${articleId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
