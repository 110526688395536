import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { usePlaylistCacheRecommended } from "../../domains/playlist/hooks/usePlaylistCache";
import { usePageScroll } from "../../domains/app/hooks/usePageScroll";
import { setUser } from "../../redux/user/user.reducer";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { getPlaylistById } from "../../domains/playlist/endpoints/getPlaylistById";
import { viewPlaylist } from "../../domains/playlist/endpoints/viewPlaylist";
import { savePlaylist } from "../../domains/playlist/endpoints/savePlaylist";
import { unsavePlaylist } from "../../domains/playlist/endpoints/unsavePlaylist";
import { sharePlaylist } from "../../domains/playlist/endpoints/sharePlaylist";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { ContentFormat } from "../../interfaces/content.interface";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTagline, HeaderTitle, NavBack, NavOptions } from "../../components/app/headers/layout";
import ItemList from "../../components/app/ItemList";
import CustomIcon from "../../components/CustomIcon";
import ArticleCard from "../../components/cards/ArticleCard";
import NectarCard from "../../components/cards/NectarCard";
import VideoCard from "../../components/cards/VideoCard";
import BottomPanel from "../../components/app/panels/BottomPanel";
import { SheetWrapper } from "../../components/CustomSheet";
import ShareSheet from "../../components/ShareSheet";
import {
  SoundEffect,
} from "../../interfaces";
import styled from "styled-components";
import { iosPlaySound, iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import { pickUserImage } from "../../domains/image/utils/pickUserImage";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { displayToast } from "../../components/app/AppToast";
import { PanelContainer, PanelRow } from "../../components/app/panels/layout";
import FadeIn from "react-fade-in";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { shareUrl } from "../../domains/app/utils/shareUrl";

export default function PlaylistPage() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { t } = useLangContext();
  const { currentUser } = useCurrentUser();
  const { findCachedPlaylist, updatePlaylistInAllCaches } = usePlaylistCacheRecommended();
  const { savePageScrollY, restorePageScrollY } = usePageScroll();
  const [playlist, setPlaylist] = useState<PlaylistModel | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [hasScrolled, setScrolled] = useState(false);
  const [isSheetVisible, setSheetVisible] = useState(false);
  // TODO: Move to hook.
  const isSaved = currentUser && currentUser?.playlists.find(
    (el: { _id: string }) => el._id === playlist?._id
  );

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        restorePageScrollY();

        if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");

        handlePageScroll();
        window.addEventListener("scroll", handlePageScroll);

        const playlist = await loadPlaylist();
        setPlaylist(playlist);
      } catch (error) {
        console.error("Couldn't mount public playlist page.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();

    return () => window.removeEventListener("scroll", handlePageScroll);
  }, []);

  

  useEffect(() => {
    if (playlist) {
      (async function () {
        try {
          await viewPlaylist(playlist._id);
        } catch (error) {
          console.error("Couldn't view playlist.", error);
        }
      })();
    }
  }, [playlist]);

  useEffect(() => {
    if (isSheetVisible) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  }, [isSheetVisible]);

  async function loadPlaylist() {
    try {
      let cachedPlaylist: PlaylistModel | null = null;

      // Check cache
      cachedPlaylist = findCachedPlaylist(params.id) ?? null;

      if (cachedPlaylist) {
        // NOTE: playlist content doesn't come populated.
        // It needs to be fetched and updated in the cache the first time.
        const content = [...cachedPlaylist.playlist, ...cachedPlaylist.playlistContent];
        const isCachedPlaylistValid = !content.some(item => typeof item === "string");

        // NOTE: Execute fetch request to increment view metrics.
        getPlaylistById(params.id)
          .then((playlist) => {
            updatePlaylistInAllCaches(playlist);
          })
          .catch((error) => {
            throw error;
          });

        if (isCachedPlaylistValid) {
          setLoading(false);
          return cachedPlaylist;
        }
      }

      // Display loader while fetching
      setLoading(true);
      const fetchedPlaylist = await getPlaylistById(params.id);
      setLoading(false);
      return fetchedPlaylist;
    } catch (error) {
      throw error;
    }
  }

  function handlePageScroll() {
    setScrolled(window.scrollY > 100);
  }

  // TODO: Move to hook.
  async function handleSavePlaylist() {
    try {
      if (!playlist) return;

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.savePlaylist"),
      });
      if (isRedirected) return;

      if (!isSaved) {
        iosPlaySound(SoundEffect.SAVE);
        displayToast(t("toast:success.playlistSaved"));
        await savePlaylist(playlist._id);
        dispatch(setUser({
          ...currentUser,
          playlists: [
            ...currentUser.playlists,
            playlist,
          ],
        }));
      } else {
        displayToast(t("toast:success.playlistUnsaved"));
        await unsavePlaylist(playlist._id);
        dispatch(setUser({
          ...currentUser,
          playlists: [
            ...currentUser.playlists.filter(
              (el: { _id: string }) => el._id !== playlist._id
            ),
          ],
        }));
      }
    } catch (error) {
      console.error("Couldn't save/unsave playlist.", error);
      displayToast(t("error:default"));
    }
  }

  // TODO: Move to hook.
  async function handleSharePlaylist() {
    try {
      if (!playlist) return;

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.sharePlaylist"),
      });
      if (isRedirected) return;

      await sharePlaylist(playlist._id);

      const url = `${window.location.origin}/playlist/public/${playlist._id}?shared=${currentUser?.uid ?? "null"}`;
      const text = t("share:playlist.message", { title: playlist.title });
      await shareUrl(document.title, text, url);

      displayToast(t("toast:success:playlistShared"), "success");
    } catch (error) {
      console.error("Couldn't share playlist.", error);
      displayToast(t("error:default"));
    }
  }

  if (isLoading || !playlist) return <Loader />;

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe transparent={!hasScrolled}>
          <HeaderLinks>
            <NavBack onClick={() =>
              !!location.key
                ? history.goBack()
                : history.replace(`/discovery`)
            } />
          </HeaderLinks>
          <HeaderTitle>
            <HeaderTagline>{t("content:playlistPage.playlistBy")}</HeaderTagline>
            {hasScrolled && (
              <>{playlist?.user?.fullname ?? playlist?.company?.name}</>
            )}
          </HeaderTitle>
          <HeaderLinks>
            <NavOptions onClick={() => setSheetVisible(true)} />
          </HeaderLinks>
        </HeaderBoxAutoSafe>

        <PageHeader>
          {!!playlist.user ? (
            <Link
              to={{
                pathname: "/profile/user/" + playlist?.user?.uid,
                state: {
                  user: playlist?.user,
                },
              }}
            >
              <img
                style={{
                  borderRadius: 100,
                  objectFit: "cover",
                  width: 72,
                  height: 72,
                }}
                src={pickUserImage(playlist?.user)}
                alt=''
              />
              <div className='company_link'>
                {t("common:by") + " " + playlist.user.fullname}
              </div>
              <h5>{playlist.title}</h5>
            </Link>
          ) : !!playlist.company ? (
            <Link
              to={{
                pathname: "/company/" + playlist?.company?._id,
                state: {
                  company: playlist?.company,
                },
              }}
            >
              <img src={playlist.company?.images[0]?.url} alt='' />
              <div className='company_link'>
                {t("content:playlistPage.discoverCompany") + " " + playlist.company.name}
              </div>
              <h5>{playlist.title}</h5>
            </Link>
          ) : (
            <h5>{playlist?.title}</h5>
          )}
        </PageHeader>

        <FadeIn>
          <ItemList>
            {[
              ...playlist?.playlist,
              ...playlist?.playlistContent,
            ]
              .sort((
                a: { publication_date?: Date; publicationDate?: Date },
                b: { publication_date?: Date; publicationDate?: Date },
              ) => {
                const dateA = a.publication_date ?? a.publicationDate;
                const dateB = b.publication_date ?? b.publicationDate;
                if (dateA && dateB) {
                  if (dateA > dateB) return -1;
                  if (dateA < dateB) return 1;
                }
                return 0;
              })
              ?.map((content, index) => {
                // TODO: Replace with proper content_format parameter once populated.
                const isArticle = "article_type" in content;
                const isNectar = content.content_format === ContentFormat.NECTAR;
                const isVideo = content.content_format === ContentFormat.VIDEO;

                if (isArticle) {
                  return (
                    <ArticleCard
                      key={index}
                      article={content}
                      analyticsListName={AnalyticsItemList.PLAYLIST_PAGE}
                      onClick={savePageScrollY}
                    />
                  )
                }
                if (isNectar) {
                  return (
                    <NectarCard
                      key={index}
                      nectar={content}
                      analyticsListName={AnalyticsItemList.PLAYLIST_PAGE}
                      onClick={savePageScrollY}
                    />
                  );
                }
                if (isVideo) {
                  return (
                    <VideoCard
                      key={index}
                      video={content}
                      analyticsListName={AnalyticsItemList.PLAYLIST_PAGE}
                      onClick={savePageScrollY}
                    />
                  );
                }
              })}
          </ItemList>
        </FadeIn>
      </Wrapper>

      <BottomPanel
        isOpen={isSheetVisible}
        onClose={() => setSheetVisible(false)}
      >
        <SheetWrapper style={{ height: window.innerHeight * 0.3 }}>
          <PanelContainer>
            <PanelRow
              style={{ color: isSaved ? "#FF699C" : "#212121" }}
              onClick={handleSavePlaylist}
            >
              <CustomIcon
                iconName={isSaved ? "save.fill" : "save"}
                color='#212121'
              />
              <div className="text">
                <span className="label">{isSaved
                  ? t("common:panel.playlist.label.unsave")
                  : t("common:panel.playlist.label.save")
                }</span>
                <span className="description">{isSaved
                  ? t("common:panel.playlist.text.unsave")
                  : t("common:panel.playlist.text.save")
                }</span>
              </div>
            </PanelRow>
            <PanelRow onClick={handleSharePlaylist}>
              <CustomIcon iconName='share-alt' color='#212121' />
              <div className="text">
                <span className="label">{t("common:panel.playlist.label.share")}</span>
                <span className="description">{t("common:panel.playlist.text.share")}</span>
              </div>
            </PanelRow>
          </PanelContainer>
        </SheetWrapper>
      </BottomPanel>

      <ShareSheet type='playlist' />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  background: #f7f8fc;
`;

const PageHeader = styled.div`
  margin-bottom: 16px;
  padding: 70px 21px 40px;
  padding-top: calc(70px + var(--safe-area-top));;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    144.76deg,
    #ffffff 0%,
    #ecf0f5 70.37%,
    #fcc408 139.87%
  );

  .company_link {
    color: #f1353a;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.04em;
    background: #fff;
    border-radius: 5rem;
    padding: 8px 16px;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    display: table;
    margin: 0px auto 20px;
  }

  img {
    display: block;
    margin: 0px auto 10px;
    /* padding: 10px 16px; */
    width: 120px;
    height: 84px;
    border-radius: 10px;
    /* background: #fff; */
    /* box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1); */
    object-fit: contain;
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #212121;
    margin: 0 auto;
  }
`;
