import dotenv from "dotenv";
import { getApps, initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  setPersistence,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  User,
  UserCredential,
} from "firebase/auth";
import "firebase/messaging";
import "firebase/analytics";
import { getCurrentUser } from "../domains/user/endpoints/getCurrentUser";
import { createUser } from "../domains/user/endpoints/createUser";
import { deleteCurrentUser } from "../domains/user/endpoints/deleteCurrentUser";
import { registerUserFirstLogin } from "../domains/user/endpoints/registerUserFirstLogin";
import { getUserStartingPage } from "../domains/user/utils/getUserStartingPage";
import { updateActiveSession } from "../domains/user/endpoints/updateActiveSession";
import { push, replace } from "react-router-redirect";
import {
  iosAppleSignIn,
  iosGetFirebaseToken,
  iosGoogleSignIn,
  isNativeIOS,
} from "../tools/ios";
import { registerFirebaseToken } from "../domains/notification/endpoints/registerFirebaseToken";
import { displayToast } from "../components/app/AppToast";
import { t } from "i18next";
import {
  setLanguage,
  getTranslation,
  language,
} from "../config/fb-errors-translator/config";
import { getCurrentOS } from "../tools/utils";
import {
  androidGetFirebaseToken,
  androidSignIn,
  isNativeAndroid,
} from "../tools/android";
import i18n from "../config/i18n";
import { FIREBASE_CONFIG, FIREBASE_VAPID_KEY } from "../config";
import {
  gaEventAuthLogin,
  gaEventAuthSignup,
} from "../tools/analytics/authAnalytics";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

declare const window: Window & typeof globalThis & { services: any };

dotenv.config();

const defaultApp = initializeApp(FIREBASE_CONFIG);
export const auth = getAuth(defaultApp);
export const analytics = getAnalytics(defaultApp); // NOTE: Used for Google Analytics

getAuth(defaultApp).languageCode = i18n.resolvedLanguage ?? "en";

setLanguage((i18n.resolvedLanguage as language) ?? "en"); // Language des erreurs
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

// Wait for token to refresh
export async function getFirebaseToken() {
  if (auth.currentUser) return await auth.currentUser.getIdToken();
  else
    return new Promise((resolve, _reject) => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken();
          // console.log("Refresh Firebase token...");
          // console.log("firebase token", token);
          resolve(token);
        } else {
          resolve(null);
        }
      });
    });
}

/** Firebase Cloud Messaging (Push notification) */
let messaging: any;
let isMessagingSupported = false;
isSupported().then((supported) => {
  if (supported) {
    messaging = getMessaging(defaultApp);
    isMessagingSupported = true;
  }
});

async function getFirebaseCloudMessagingToken() {
  if (isMessagingSupported) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: FIREBASE_VAPID_KEY,
      });
      if (currentToken) {
        localStorage.setItem("fb_token", currentToken);
        // TODO: missing catch.
        registerFirebaseToken(currentToken);
      } else {
        // Show permission request UI
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function onMessageListener() {
  if (isMessagingSupported) {
    onMessage(messaging, (payload) => {
      var notification = new Notification(
        payload.notification?.title as string,
        {
          body: payload.notification?.body,
        }
      );
      notification.onclick = function (event) {
        event.preventDefault(); // prevent the browser from focusing on the navigation tab
        window.location.pathname = payload.data?.link as string;
      };
    });
  }
}

export async function initFirebaseCloudMessaging() {
  const app_os = getCurrentOS();

  if (isNativeIOS || window.services) {
    if (app_os === "ios") {
      setTimeout(() => {
        iosGetFirebaseToken();
        const ios_fb_token = localStorage.getItem("ios_fb_token");
        if (ios_fb_token) {
          registerFirebaseToken(ios_fb_token?.toString());
        } else {
          console.error("No iOS token.");
        }
      }, 10000);
    }

    if (app_os === "android") {
      setTimeout(() => {
        const android_fb_token = androidGetFirebaseToken();
        console.log("android_fb_token", android_fb_token);
        // TODO: missing catch.
        registerFirebaseToken(android_fb_token);
      }, 10000);
    }
  } else {
    ////// FCM NOTIFICATIONS WEB APP //////
    if ("Notification" in window) Notification.requestPermission();

    // Getting authorization token...
    getFirebaseCloudMessagingToken();

    // Setting our listener when on window...
    onMessageListener();
  }
}

/** OAuth handler & catcher */
async function authHandler(
  res: UserCredential,
  additionalData?: Record<string, any>
) {
  const { user } = res;

  console.log("users", user);
  console.log("additionalData", additionalData);

  const firstName = additionalData?.firstname || user.displayName?.trim().split(" ")[0];
  const lastName = additionalData?.firstname || (user.displayName?.trim().split(" ")[1] ?? "");

  /** Data format depends of provider */
  const data = {
    uid: user.uid,
    email: user.email,
    firstname: firstName,
    lastname: lastName,
    device: getCurrentOS(),
  };

  if (user.metadata.creationTime === user.metadata.lastSignInTime) {
    const createUserRes = await createUser(data);

    if (!createUserRes) {
      console.error("[firebase.js] Error creating user");
      throw new Error("[firebase.js] Error creating user");
    }

    gaEventAuthSignup(user.uid);
    push("/signup/onboarding");
  } else {
    const user = await getCurrentUser();
    gaEventAuthLogin(user.uid);
    updateActiveSession(true);

    localStorage.removeItem("currentRoomId");
    if (localStorage.getItem("_cache_notification")) {
      replace(
        "/profile/notifications/" + localStorage.getItem("_cache_notification")
      );
    } else if (localStorage.getItem("authRedirectPath")) {
      replace(localStorage.getItem("authRedirectPath"));
      localStorage.removeItem("authRedirectPath");
    } else {
      if (!user.meta.firstLogin) {
        await registerUserFirstLogin();
      }
      const path = await getUserStartingPage(user);
      replace(path ?? "/foryou");
    }
  }
}

function authCatcher(error: any) {
  console.error("error", error);
  if (error) {
    displayToast("An error has been occured:" + error?.code);
  }
}

/** Google OAuth */
export function loginWithGoogle() {
  if (isNativeIOS) {
    iosAuthSignIn("google");
  } else if (isNativeAndroid) {
    androidAuthSignIn();
  } else {
    popupAuthGoogle();
  }
}

const androidAuthSignIn = async () => {
  try {
    await androidSignIn();
    const checkCallback = setInterval(() => {
      const androidSigninCallback = localStorage.getItem(
        "android_signin_callback"
      );
      console.log({ androidSigninCallback });
      if (!!androidSigninCallback) {
        clearInterval(checkCallback);

        /** Getting auth callback result */
        const callback = JSON.parse(androidSigninCallback);
        console.log({ callback });

        /** Native Android Auth */
        if (callback.success) {
          const { idToken } = callback;
          const credentials = GoogleAuthProvider.credential(idToken);

          signInWithCredential(auth, credentials)
            .then(authHandler)
            .catch(authCatcher);
        }
      } else {
        console.log("checking Android native auth callback...");
      }
    }, 1500);
  } catch (error) {
    console.error("Firebase: couldn't sign in (Android).", error);
    displayToast(t("Firebase: couldn't sign in (Android)."));
  }
};

async function popupAuthGoogle() {
  signInWithPopup(auth, googleProvider).then(authHandler).catch(authCatcher);
}

/** Apple OAuth */

export async function loginWithApple() {
  if (isNativeIOS) {
    iosAuthSignIn("apple");
  } else {
    popupAuthApple();
  }
}

async function iosAuthSignIn(provider: any) {
  try {
    if (provider === "apple") await iosAppleSignIn();
    else await iosGoogleSignIn();

    localStorage.removeItem("ios_signin_callback");

    const checkCallback = setInterval(async () => {
      const iosSigninCallback = localStorage.getItem("ios_signin_callback");

      if (!!iosSigninCallback) {
        clearInterval(checkCallback);

        /** Getting auth callback result */
        const callback = JSON.parse(iosSigninCallback);
        console.log({ callback });

        /** Native Apple Auth */
        if (callback.success) {
          const { idToken } = callback;

          let credentials;

          if (provider === "apple")
            credentials = appleProvider.credential({
              ...callback,
            });
          else credentials = GoogleAuthProvider.credential(idToken);

          signInWithCredential(auth, credentials as OAuthCredential)
            .then((res) =>
              authHandler(res, {
                firstname: callback?.firstname,
                lastname: callback?.lastname,
              })
            )
            .catch(authCatcher);
        }
      } else {
        console.log("checking iOS native auth callback...");
      }
    }, 1500);
  } catch (error) {
    console.error("Firebase: couldn't sign in (iOS).", error);
    displayToast(t("Firebase: couldn't sign in (iOS)."));
  }
}

async function popupAuthApple() {
  signInWithPopup(auth, appleProvider).then(authHandler).catch(authCatcher);
}

/** Basic Auth (Email/Password) */
export async function loginEmailPassword(email: string, password: string) {
  let isSuccess = false;
  let errorMessage = "";

  await setPersistence(auth, browserLocalPersistence).then(async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      isSuccess = true;
      initFirebaseCloudMessaging();
      updateActiveSession(true);

      const user = await getCurrentUser();

      gaEventAuthLogin(user.uid);

      localStorage.removeItem("currentRoomId");
      if (localStorage.getItem("_cache_notification")) {
        replace(
          "/profile/notifications/" +
          localStorage.getItem("_cache_notification")
        );
      } else if (localStorage.getItem("authRedirectPath")) {
        replace(localStorage.getItem("authRedirectPath"));
        localStorage.removeItem("authRedirectPath");
      } else {
        if (!user.meta.firstLogin) {
          await registerUserFirstLogin();
        }
        const path = await getUserStartingPage(user);
        replace(path ?? "/foryou");
      }
    } catch (error: any) {
      console.error("Couldn't submit login with email/password.", error);
      errorMessage = error?.code || "auth/internal-error";
      isSuccess = false;
    }
  });
  return { isSuccess, errorMessage };
}

export const signupEmailPassword = async (
  email: string,
  password: string,
  data: any
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    data.uid = user?.uid;
    data.email = email;
    data.device = getCurrentOS();

    const createUserRes = await createUser(data);
    if (!createUserRes) {
      console.error("[firebase.js] Error creating user");
      throw new Error("[firebase.js] Error creating user");
    }

    gaEventAuthSignup(user.uid);
    push("/signup/onboarding");

    return true;
  } catch (error: any) {
    // Deleting user if creation failed
    if (!error.code) {
      const user = await getCurrentUser();
      deleteUser(user as unknown as User);
      deleteCurrentUser();
    }

    displayToast(t(error?.message));
    throw error;
  }
};

export async function completeMagicLink(url: string) {
  try {
    const email = window.localStorage.getItem("emailForSignIn") as string;
    await signInWithEmailLink(auth, email, url);

    push("/signup/newpassword");
  } catch (error: any) {
    if (error) displayToast(t(error?.message));
    throw error;
  }
}

/** Firebase Auth Handler */

export function firebaseLogout() {
  auth.signOut();
}

export function firebaseRefresh() {
  auth.currentUser
    ?.getIdToken()
    .then(function (idToken) {
      console.log("fbRefresh", idToken);
    })
    .catch(function (error) {
      console.log("fbRefresh", error);
    });
}
