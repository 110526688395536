import { SupportedLanguage } from "../../../interfaces";
import { getApiRequestService } from "../../../services/index.services";
import { NectarModel } from "../nectar.types";

interface QueryParams {
  language: SupportedLanguage;
}

export async function getNectarHighlights(params: QueryParams): Promise<NectarModel[]> {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/nectar/highlight`, {
      params,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
