import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { usePageScroll } from "../../domains/app/hooks/usePageScroll";
import { useVideoCacheStories } from "../../domains/video/hooks/useVideoCache";
import { VideoModel } from "../../domains/video/video.types";
import styled from "styled-components";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavBack,
} from "../../components/app/headers/layout";
import Loader from "../../components/Loader";
import JuisciPattern from "../../assets/images/mobile-pattern.svg";
import JuisciBackground from "../../assets/images/mobile-pattern.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useVideoStoriesListWithCache } from "../../domains/video/endpoints/getVideoStoriesList";
import disableScroll from "disable-scroll";
import { displayToast } from "../../components/app/AppToast";
import FadeIn from "react-fade-in";

export default function VideoStoryListPage() {
  const history = useHistory();
  const { t, activeLang } = useLangContext();
  const [isLoading, setLoading] = useState(false);
  const { list: videoStories, total: totalVideoStories } = useVideoCacheStories();
  const getVideoStoriesList = useVideoStoriesListWithCache();
  const { restorePageScrollY, savePageScrollY } = usePageScroll();

  useEffect(() => {
    disableScroll.off();
    if (videoStories.length === 0) loadVideoStories();
    restorePageScrollY();
  }, []);

  async function loadVideoStories() {
    try {
      setLoading(true);
      await getVideoStoriesList({
        limit: 20,
        offset: -1,
        language: activeLang,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      displayToast(t("error:default"));
    }
  }

  if (isLoading && videoStories.length === 0) return <Loader />;

  return (
    <PageContainer backgroundImage={JuisciPattern}>
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <HeaderTitle>{t("content:type.videos")}</HeaderTitle>
        <HeaderLinks />
      </HeaderBoxAutoSafe>

      <div className="content" id="content-ref">
        <FadeIn>
          <InfiniteScroll
            dataLength={videoStories.length}
            next={loadVideoStories}
            hasMore={videoStories.length < totalVideoStories}
            loader={<Loader loaderOnly />}
          >
            <div className="grid">
              {videoStories.map((story: VideoModel) => {
                return (
                  <div
                    key={story._id + "--story-homevideocard"}
                    className="story-card"
                    onClick={() => {
                      console.log(window.scrollY);

                      savePageScrollY();
                      history.push({
                        pathname: "/video/story/" + story.slug,
                        state: { story },
                      })
                    }}
                  >
                    <img src={story.apiVideo?.thumbnail} alt="" />
                    <p>{story.title}</p>
                  </div>
                )
              })}
            </div>
          </InfiniteScroll>
        </FadeIn>
      </div>
    </PageContainer>
  );
}

const PageContainer = styled.div<{ backgroundImage: string }>`
  min-height: 100dvh;
  background-image: linear-gradient(
    123.08deg,
    rgba(255, 255, 255, 0.97) 0%,
    rgba(236, 240, 245, 0.97) 40%,
    rgba(251, 200, 28, 0.97) 150%,
    rgba(252, 196, 8, 0.97) 200%
  ),
  url(${(props) => props.backgroundImage});
  background-attachment: fixed;

  .content {
    padding: 21px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    justify-items: center;
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 4px;
  }

  .story-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;

    img {
      width: 100%;
      aspect-ratio: 9/16;
      object-fit: cover;
      border: 2px solid #ffc408;
      border-radius: 10px;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0.8) 0%,
          rgba(240, 240, 240, 0.9) 100%
        ),
        url(${JuisciBackground}) !important;
      background-size: contain;
      background-color: #e5e5e5;
    }

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 130%;
      text-align: center;
      color: #212121;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;

    span {
      color: #ce0868;
    }
  }

  .page-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 21px;
  }
`;
