//
// All Android JS Functions here...
//
import { getCurrentOS } from "./utils";

// TODO: Refactor/type.

declare const window: Window & typeof globalThis & { services: any };

enum PushAuthorizationStatus {
  NOT_DETERMINED = "notDetermined",
  AUTHORIZED = "authorized",
  DENIED = "denied",
}

export const isNativeAndroid = getCurrentOS() === "android" && window.services != null;

export function androidGetVersion() {
  if (isNativeAndroid) {
    return window.services.getVersion();
  }
}

export function androidGetNotificationState() {
  return window.services.getNotificationState();
}

export function androidRequestPushAuthorization() {
  try {
    const { status } = JSON.parse(androidGetNotificationState());
    if (status === PushAuthorizationStatus.NOT_DETERMINED) {
      window.services.requestPushAuthorization("androidRequestPushAuthorizationCallback");
    }
  } catch (error) {
    console.error("Couldn't request authorization", error);
  }
}

export const androidGetFirebaseToken = () => {
  if (isNativeAndroid) {
    const res = JSON.parse(window.services.getFirebaseToken());
    return res.token;
  }
};

export const androidClearData = () => {
  if (isNativeAndroid) window.services?.clearData();
};

export const androidOffline = () => {
  if (isNativeAndroid) window.services?.offline();
};

export const androidShare = (content: string, link: string) => {
  if (isNativeAndroid) window.services.share(link + "\n" + content);
};

export const androidOpen = (link: string) => {
  if (isNativeAndroid) window.services.open(link);
};

export const androidSignIn = async () => {
  if (isNativeAndroid)
    window.services.startSignInFlow("google", "androidSignInCallback");
};

export const androidSignOut = async () => {
  if (isNativeAndroid) window.services.signOut();
};

export const androidDismissKeyboard = async () => {
  if (isNativeAndroid) window.services.dismissKeyboard();
};

export const androidRequestReview = async () => {
  if (isNativeAndroid) window.services.requestReview();
};

export const androidSetAppUrl = async (url: string) => {
  if (isNativeAndroid) window.services.setAppUrl(url);
};

export const androidGetAppUrl = async () => {
  if (isNativeAndroid) window.services.getAppUrl();
};

export const androidResetAppUrl = async () => {
  if (isNativeAndroid) window.services.resetAppUrl();
};
