import { ReactChildren, ReactNode, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { clearCachedResults, clearContextTab } from "../../domains/search/search.reducer";
import SafeAreaBottomWrapper from "./SafeAreaBottomWrapper";
import ProfilePicture from "../ProfilePicture";
import LoginPanel from "../signup/LoginPanel";
import OnboardingPanel from "../signup/OnboardingPanel";
import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as NavDiscoveryDuotoneIcon } from "../../assets/icons/nav/nav-discovery-duotone.svg";
import { ReactComponent as NavDiscoveryRegularIcon } from "../../assets/icons/nav/nav-discovery-regular.svg";
import { ReactComponent as NavGptYellowIcon } from "../../assets/icons/nav/nav-gpt-yellow.svg";
import { ReactComponent as NavGptGreyIcon } from "../../assets/icons/nav/nav-gpt-grey.svg";
import { ReactComponent as NavForYouDuotoneIcon } from "../../assets/icons/nav/nav-foryou-duotone.svg";
import { ReactComponent as NavForYouRegularIcon } from "../../assets/icons/nav/nav-foryou-regular.svg";
import { ReactComponent as NavSearchYellowIcon } from "../../assets/icons/nav/nav-search-yellow.svg";
import { ReactComponent as NavSearchGreyIcon } from "../../assets/icons/nav/nav-search-grey.svg";
import { ReactComponent as NavProfileYellowIcon } from "../../assets/icons/nav/nav-profile-yellow.svg";
import { ReactComponent as NavProfileGreyIcon } from "../../assets/icons/nav/nav-profile-grey.svg";
import { isUserOnboarded } from "../../domains/user/utils/isUserOnboarded";

export default function BottomNavbar() {
  const dispatch = useAppDispatch();
  const { currentUser } = useCurrentUser();
  const [isLoginPanelVisible, setLoginPanelVisible] = useState(false);
  const [isOnboardingPanelVisible, setOnboardingPanelVisible] = useState(false);

  return (
    <>
      <OuterContainer>
        <SafeAreaBottomWrapper>
          <InnerContainer>
            <NavLink to="/discovery" icons={[<NavDiscoveryDuotoneIcon />, <NavDiscoveryRegularIcon />]}>
              {t("common:nav.discovery")}
            </NavLink>

            <NavLink to="/gpt/chat/new" icons={[<NavGptYellowIcon />, <NavGptGreyIcon />]}>
              {t("common:nav.gpt")}
            </NavLink>

            <NavLink to="/foryou" icons={[<NavForYouDuotoneIcon />, <NavForYouRegularIcon />]}>
              {t("common:nav.forYou")}
            </NavLink>

            <NavLink to="/search" icons={[<NavSearchYellowIcon />, <NavSearchGreyIcon />]} onClick={() => {
              // NOTE: We clear cached search data, considering users
              // start a new search cycle clicking this link.
              dispatch(clearCachedResults());
              dispatch(clearContextTab());
            }}>
              {t("common:nav.search")}
            </NavLink>

            <NavLink
              to={"/profile"}
              icons={[
                !!currentUser ? <ProfilePicture user={currentUser} height={24} /> : <NavProfileYellowIcon />,
                !!currentUser ? <ProfilePicture user={currentUser} height={24} /> : <NavProfileGreyIcon />,
              ]}
              preventNavigate={!currentUser || !isUserOnboarded(currentUser)}
              onClick={() => {
                if (!currentUser) {
                  setLoginPanelVisible(true);
                } else if (!isUserOnboarded(currentUser)) {
                  setOnboardingPanelVisible(true);
                }
              }}
            >
              {t("common:nav.profile")}
            </NavLink>
          </InnerContainer>
        </SafeAreaBottomWrapper>
      </OuterContainer>

      <LoginPanel
        isOpen={isLoginPanelVisible}
        onClose={() => setLoginPanelVisible(false)}
      />
      <OnboardingPanel
        isOpen={isOnboardingPanelVisible}
        onClose={() => setOnboardingPanelVisible(false)}
      />
    </>
  );
}

const OuterContainer = styled.div`
  background: #FFF;
  box-shadow: 0px -4px 10px 0px rgba(33, 33, 33, 0.10);
`;

const InnerContainer = styled.div`
  padding: 8px 32px 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

function NavLink({ children, to, icons, preventNavigate, onClick }: {
  children: ReactNode | ReactChildren;
  to: string;
  icons: any[];
  preventNavigate?: boolean;
  onClick?: Function;
}) {
  const { pathname } = useLocation();
  const [activeIcon, inactiveIcon] = icons;

  return (
    <NavLinkContainer to={to} onClick={(e) => {
      if (preventNavigate) e.preventDefault();
      onClick && onClick();
    }}>
      {pathname.startsWith(to) ? activeIcon : inactiveIcon}
      <span className={pathname.startsWith(to) ? "active" : ""}>{children}</span>
    </NavLinkContainer>
  );
}

const NavLinkContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  svg {
    width: 28px;
    height: 28px;
  }

  span {
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-4, #90A4AE);
    text-align: center;
    font-family: Inter;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: -0.09px;

    &.active {
      color: #212121;
    }
  }
`;