import { atom, useAtom } from "jotai"
import { VideoModel } from "../video.types";
import { DebugLogger } from "../../app/utils/debugLogger";

const debug = new DebugLogger("Video Cache");
debug.mute();

enum CacheKey {
  STORIES = "stories",
  ARTICLES = "articles",
}

interface VideoListCache {
  list: VideoModel[];
  total: number;
}

const initialCache: {
  [CacheKey.STORIES]: VideoListCache;
  [CacheKey.ARTICLES]: VideoListCache;
} = {
  [CacheKey.STORIES]: { list: [], total: 0 },
  [CacheKey.ARTICLES]: { list: [], total: 0 },
};

const cacheListsAtom = atom(initialCache);

function useVideoCache(cacheId: CacheKey) {
  const [cacheLists, setCacheLists] = useAtom(cacheListsAtom);

  function update(docs: VideoModel[], total: number) {
    debug.log("Save cache", { cacheId, docs, total });
    setCacheLists((lists) => ({ ...lists, [cacheId]: { list: docs, total } }));
  }

  return {
    list: cacheLists[cacheId].list,
    total: cacheLists[cacheId].total,
    update,
    clear: () => update([], 0),
  }
}

export function useClearVideoCache() {
  const [, setCacheLists] = useAtom(cacheListsAtom);
  return () => setCacheLists(initialCache);
}

export function useVideoCacheStories() {
  return useVideoCache(CacheKey.STORIES);
}

export function useVideoCacheArticles() {
  return useVideoCache(CacheKey.ARTICLES);
}
