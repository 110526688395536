import { SupportedLanguage } from "../../../interfaces";
import { getApiRequestService } from "../../../services/index.services";
import { useVideoCacheStories } from "../hooks/useVideoCache";
import { VideoFormat } from "../video.types";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguage;
  medicalSpecialties?: string;
  tags?: string;
}

export async function getVideoStoriesList(params: QueryParams) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/video`, {
      params: {
        ...params,
        videoFormat: VideoFormat.STORY,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export function useVideoStoriesListWithCache() {
  const cache = useVideoCacheStories();

  return async function (params: QueryParams) {
    const isAdding = params.offset === -1;
    if (isAdding) params.offset = cache.list.length;

    const { docs, meta } = await getVideoStoriesList(params);
    cache.update(
      isAdding ? [...cache.list, ...docs] : docs,
      meta.total,
    );

    return { docs, meta };
  }
}
